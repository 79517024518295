import { isTenant } from '@/utils/config'
import { ComponentType, createContext, PropsWithChildren } from 'react'

export type TrackingProps = {
  memberId: string | null
  platform: 'desktop web' | 'mobile web' | 'mobile app android' | 'mobile app ios'
  product: string
  affiliation: 'tk' | 'ww'
  referrer?: string
}

export const TrackingContext = createContext<TrackingProps | null>(null)

export const TrackingProvider: ComponentType<PropsWithChildren<Omit<TrackingProps, 'affiliation'>>> = ({
  memberId,
  platform,
  product,
  children,
  referrer,
}) => {
  const brandedMemberId = isTenant('tk') ? memberId : null

  return (
    <TrackingContext.Provider
      value={{
        affiliation: isTenant('tk') ? 'tk' : 'ww',
        memberId: brandedMemberId ?? null,
        platform,
        product,
        referrer,
      }}
    >
      {children}
    </TrackingContext.Provider>
  )
}
