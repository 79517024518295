//  This context is responsible for state mgmt on the front-end
// Context like these solves the problem of passing state, as well as state crud hooks, down to any children component

import {
  type ComponentType,
  createContext,
  useReducer,
  useContext,
  type PropsWithChildren,
  type ReactNode,
  useEffect,
} from 'react'
import Router from 'next/router'
import { parse } from 'cookie'

export interface MemberData {
  email: string
  id: string
  legacyUserId: string
  createdAt: string
}

export type Action =
  | { type: 'memberFetchSuccess'; payload: MemberData }
  | { type: 'memberFetchFailed' }
  | { type: 'logoutSuccess' }
  | { type: 'logoutFailed' }
  | { type: 'memberFetchStatus'; payload: { fetched: boolean } }

export type MembershipDispatch = (action: Action) => void

export type MembershipState = { member: MemberData | null; authenticated: boolean; fetched: boolean }

type MembershipProviderProps = { children: ReactNode; cookies?: string }

const MembershipStateContext = createContext<MembershipState | undefined>(undefined)

const MembershipDispatchContext = createContext<MembershipDispatch | undefined>(undefined)

const initialState = { member: null, authenticated: false, fetched: false }

const membershipFetchReducer = (state: MembershipState = initialState, action: Action) => {
  switch (action.type) {
    case 'memberFetchSuccess': {
      return { ...state, member: action.payload, authenticated: true }
    }
    case 'memberFetchFailed': {
      return { ...state, member: null, authenticated: false }
    }
    case 'memberFetchStatus': {
      return { ...state, fetched: action.payload.fetched }
    }
    default: {
      return null
    }
  }
}

const membershipLogoutReducer = (state: MembershipState = initialState, action: Action) => {
  switch (action.type) {
    case 'logoutSuccess': {
      return { ...state, member: null, authenticated: false }
    }
    case 'logoutFailed': {
      return state
    }
    default: {
      return null
    }
  }
}

// Exported only for testing
export const membershipReducer = (state: MembershipState = initialState, action: Action) => {
  const reducers = [membershipFetchReducer, membershipLogoutReducer]
  let newState = null

  for (const reducer of reducers) {
    newState = reducer(state, action)
    if (newState) break
  }

  return newState || initialState
}

export const fetchMember = async (dispatch: MembershipDispatch) => {
  try {
    dispatch({ type: 'memberFetchStatus', payload: { fetched: false } })
    const membershipServiceClient = (await import('@/modules/core/services/membership.service')).default
    const response = await membershipServiceClient.getMember()
    const payload = response.body.members
    dispatch({ type: 'memberFetchSuccess', payload })
    return payload
  } catch (error) {
    dispatch({ type: 'memberFetchFailed' })
  } finally {
    dispatch({ type: 'memberFetchStatus', payload: { fetched: true } })
  }
}

export const logOut = async (dispatch: MembershipDispatch) => {
  try {
    const membershipServiceClient = (await import('@/modules/core/services/membership.service')).default
    const { refreshClientSession } = await import('@/modules/core/services/session.service')

    await membershipServiceClient.logOut()
    await refreshClientSession()
    document.cookie = 'xo-session-token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    dispatch({ type: 'logoutSuccess' })

    const currentRoute = Router.asPath
    const shouldRedirect = /canvas|review/.test(currentRoute)
    Router.replace(shouldRedirect ? '/products' : Router.asPath)
  } catch (err) {
    dispatch({ type: 'logoutFailed' })
  }
}

export const syncMembershipStateAndToken = async (
  cookies: string | undefined,
  state: MembershipState,
  dispatch: MembershipDispatch
) => {
  let hasToken = false

  if (cookies) {
    const parsedCookies = parse(cookies)
    const { 'xo-session-token': tkToken, 'tkww-access-token': wwToken } = parsedCookies
    hasToken = !!tkToken || !!wwToken
  }

  if (hasToken === state.authenticated) {
    return
  }

  if (hasToken) {
    await fetchMember(dispatch)
  } else {
    await logOut(dispatch)
  }
}

export const MembershipProvider: ComponentType<PropsWithChildren<MembershipProviderProps>> = ({
  children,
  cookies,
}: MembershipProviderProps) => {
  const [state, dispatch] = useReducer(membershipReducer, initialState)

  useEffect(() => {
    void syncMembershipStateAndToken(cookies, state, dispatch)
  }, [cookies])

  return (
    <MembershipStateContext.Provider value={state}>
      <MembershipDispatchContext.Provider value={dispatch}>{children}</MembershipDispatchContext.Provider>
    </MembershipStateContext.Provider>
  )
}

/* context wrapping, convenience hooks, 
 so components don't have to do `useContext(X)`
 simply use the hooks and extract the context data providing a state hook like interface
*/

export const useMembershipState = () => {
  const context = useContext(MembershipStateContext)

  if (context === undefined) {
    throw new Error('useMembershipState must be used within a MembershipProvider')
  }

  return context
}

const useMembershipDispatch = () => {
  const context = useContext(MembershipDispatchContext)

  if (context === undefined) {
    throw new Error('useMembershipDispatch must be used within a MembershipProvider')
  }

  return context
}

// This Hook simply returns both the state & the dispatch contexts
export const useMembership = () => {
  try {
    return [useMembershipState(), useMembershipDispatch()]
  } catch (e) {
    throw new Error('useMembership hook  must be used within a MembershipProvider')
  }
}
