export const RemoveDraftGroup = /* GraphQL */ `
  mutation RemoveDraftGroup($input: DeleteDraftGroupInput!) {
    drafts {
      removeItemsByDraftGroup(input: $input) {
        count
      }
    }
  }
`

export const RemoveDraft = /* GraphQL */ `
  mutation RemoveDraft($input: DeleteDraftsItemInput!) {
    drafts {
      removeItem(input: $input) {
        id
      }
    }
  }
`

export const DraftsCount = /* GraphQL */ `
  query getDraftsCount {
    drafts {
      findAllForUser {
        count
      }
    }
  }
`

export const FindAllDrafts = /* GraphQL */ `
  query findAllDrafts {
    drafts {
      findAllForUser {
        count
        items {
          id
          draftGroupId
          image
          updatedAt
          portfolioItem {
            id
            memberId
            notes
            tenant
            customizer {
              stateId
              urlPrint
              urlProofs {
                position
                url
              }
              userId
            }
            variation {
              id
              catalogProductId
              colorThemeCode
              optionValues {
                name
                valueId
                optionId
              }
              envelopePrintingOptionCode
              flapTypeCode
              foilCode
              isSample
              paperColorCode
              paperTypeCode
              productCode
              suiteCode
              tenant
              variationImages {
                imageUrl
                tag
              }
              product {
                name
                slug
                type {
                  name
                }
              }
            }
          }
          price
          quantity
        }
      }
    }
  }
`

export const MoveToCart = /* GraphQL */ `
  mutation moveToCart($input: AddDraftToCartInput!) {
    draftCart {
      moveDraftItemToCart(input: $input) {
        cart {
          currencyCode
          storeCartId
          totalAmount
          items {
            id
            quantity
            price {
              basePrice
            }
          }
        }
        item {
          catalogProductId
          collectionName
          colorThemeName
          envelopePrintingName
          foilThemeName
          id
          paperTypeName
          parentId
          productName
          productTypeName
          slug
          price {
            basePrice
          }
          portfolioItem {
            customizer {
              urlProofs {
                url
              }
            }
          }
          variation {
            id
            catalogProductId
            colorThemeName
            colorThemeCode
            envelopePrintingOptionCode
            envelopePrintingOptionName
            flapTypeCode
            foilCode
            foilName
            isSample
            paperColorCode
            paperTypeCode
            paperTypeName
            productCode
            suiteCode
            tenant
            product {
              name
              productFormat {
                name
              }
            }
            optionValues {
              name
              optionId
              valueId
            }
          }
        }
      }
    }
  }
`

export const MoveToDraft = /* GraphQL */ `
  mutation MoveToDraft($itemId: String!) {
    draftCart {
      moveCartItemToDrafts(itemId: $itemId) {
        cart {
          storeCartId
        }
        draft {
          id
        }
      }
    }
  }
`

export const UpsertDraftItem = /* GraphQL */ `
  mutation UpsertDraftItem($input: UpsertItemToDraftsInput!) {
    drafts {
      upsertItem(input: $input) {
        id
      }
    }
  }
`
