/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from './segment'

export interface ExperimentStarted {
	/**
	 * the name of the variant into which the user was bucketed, for that experiment
	 */
	'Experiment name': string
	/**
	 * the name of the experiment to which the user has been exposed
	 */
	'Variant name': string
}
export interface CtaClicked {
	category?: string
	element_clicked?: string
	location?: string
	platform?: string
	product?: string
	/**
	 * Database ID of the product
	 */
	product_id?: string
	reason?: string
}
export interface ProductsItem {
	format?: string
	/**
	 * Product ID displayed in the list
	 */
	product_id: string
}
export interface CartShared {
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id?: string
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem[]
	/**
	 * Recipient of the sharing
	 */
	recipient?: string
	/**
	 * Message that the sender sent
	 */
	share_message?: string
	/**
	 * Method of sharing
	 */
	share_via: string
}
export interface VariantItem {
	/**
	 * The color of the product
	 */
	color: string
	flap_type?: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.
	 */
	foil_color: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
	 */
	format: string
	/**
	 * Materials of the product
	 */
	material: string
	paper_color?: string
	/**
	 * Which printing option did the customer specify?
	 */
	printing_options?: string
}
export interface ProductsItem1 {
	/**
	 * Product Category displayed in the list
	 */
	category: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Image url of the product in the list
	 */
	image_url: string
	/**
	 * Product Name displayed in the list
	 */
	name: string
	/**
	 * Position of product in the list
	 */
	position: number
	/**
	 * Product Price displayed in the list
	 */
	price: number
	/**
	 * Product ID displayed in the list
	 */
	product_id: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity: number
	/**
	 * Indicates whether the product selected is a sample (true/false)
	 */
	sample: boolean
	/**
	 * Sku of the product being viewed
	 */
	sku: string
	/**
	 * URL of the product page
	 */
	url: string
	/**
	 * Variant of the product
	 */
	variant: VariantItem[]
}
export interface CartViewed {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem1[]
	/**
	 * Total value of the product after quantity
	 */
	value: string
}
export interface VariantItem1 {
	/**
	 * The color of the product
	 */
	color: string
	flap_type?: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.
	 */
	foil_color: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
	 */
	format: string
	/**
	 * Materials of the product
	 */
	material: string
	paper_color?: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
	 */
	printing_options?: string
}
export interface ProductsItem2 {
	/**
	 * Product Category displayed in the list
	 */
	category: string
	/**
	 * Coupon code associated with the product
	 */
	coupon: string
	/**
	 * Image url of the product in the list
	 */
	image_url: string
	/**
	 * Product Name displayed in the list
	 */
	name: string
	notes?: string
	/**
	 * Position of product in the list
	 */
	position: number
	/**
	 * Product Price displayed in the list
	 */
	price?: number
	/**
	 * Product ID displayed in the list
	 */
	product_id: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity: number
	/**
	 * Product SKU displayed in the list
	 */
	sku: string
	/**
	 * URL of the product page for product in the list
	 */
	url: string
	/**
	 * Product Variant displayed in the list
	 */
	variant: VariantItem1[]
}
export interface CheckoutStarted {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency: string
	/**
	 * Total discount associated with the transaction
	 */
	discount?: number
	/**
	 * Order/transaction ID
	 */
	order_id: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web"
	 */
	platform: string
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem2[]
	/**
	 * Revenue ($) associated with the transaction
	 */
	revenue: number
	/**
	 * Total value of the product after quantity
	 */
	value: string
}
export interface CheckoutStepCompleted {
	/**
	 * Checkout transaction ID
	 */
	checkout_id?: string
	/**
	 * String representing the payment method chosen
	 */
	payment_method?: string
	/**
	 * String representing the shipping method chosen
	 */
	shipping_method?: string
	/**
	 * Number representing a step in the checkout process
	 */
	step?: number
}
export interface CheckoutStepViewed {
	/**
	 * Checkout transaction ID
	 */
	checkout_id?: string
	location?: string
	/**
	 * String representing the payment method chosen
	 */
	payment_method?: string
	platform?: string
	section?: string
	/**
	 * String representing the shipping method chosen
	 */
	shipping_method?: string
	/**
	 * Number representing a step in the checkout process
	 */
	step?: number
}
export interface CouponApplied {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id: string
	/**
	 * Coupon code associated with the product
	 */
	coupon: string
	/**
	 * Name of the coupon
	 */
	coupon_name: string
	/**
	 * Total discount associated with the transaction
	 */
	discount: number
	platform: string
}
export interface CouponDenied {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id: string
	/**
	 * Coupon code associated with the product
	 */
	coupon: string
	/**
	 * Name of the coupon
	 */
	coupon_name?: string
	platform: string
	/**
	 * Reason the coupon was denied
	 */
	reason: string
}
export interface CouponEntered {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id: string
	/**
	 * Coupon code associated with the product
	 */
	coupon: string
	/**
	 * Name of the coupon
	 */
	coupon_name: string
	platform: string
}
export interface CouponRemoved {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id: string
	/**
	 * Coupon code associated with the product
	 */
	coupon: string
	/**
	 * Name of the coupon
	 */
	coupon_name: string
	/**
	 * Total discount associated with the transaction
	 */
	discount: number
	platform: string
}
export interface CustomizationCancelled {
	/**
	 * Brand of the product
	 */
	brand?: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id?: string
	/**
	 * Product category being viewed
	 */
	category?: string
	/**
	 * The color of the product
	 */
	color?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	format?: string
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Name of the product
	 */
	name?: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Price of the product
	 */
	price: number
	/**
	 * Which printing option did the customer specify?
	 */
	printing_options?: string
	/**
	 * Database ID of the product
	 */
	product_id: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * URL of the product page for product
	 */
	url?: string
	/**
	 * Variant of the product
	 */
	variant?: string
}
export interface CustomizationInitialized {
	/**
	 * Brand of the product
	 */
	brand?: boolean
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id?: string
	/**
	 * Product category being viewed
	 */
	category?: string
	/**
	 * The color of the product
	 */
	color?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	format?: string
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Name of the product
	 */
	name?: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Price of the product
	 */
	price: number
	/**
	 * Which printing option did the customer specify?
	 */
	printing_options?: string
	/**
	 * Database ID of the product
	 */
	product_id: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	sample?: boolean
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * URL of the product page for product
	 */
	url?: string
	/**
	 * Variant of the product
	 */
	variant?: string
}
export interface CustomizationInteraction {
	/**
	 * Brand of the product
	 */
	brand?: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id?: string
	/**
	 * Product category being viewed
	 */
	category?: string
	/**
	 * The color of the product
	 */
	color?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	format?: string
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Name of the product
	 */
	name?: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Price of the product
	 */
	price: number
	/**
	 * Which printing option did the customer specify?
	 */
	printing_options?: string
	/**
	 * Database ID of the product
	 */
	product_id: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * URL of the product page for product
	 */
	url?: string
	/**
	 * Variant of the product
	 */
	variant?: string
}
export interface VariantItem2 {
	/**
	 * The color of the product
	 */
	color: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.
	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
	 */
	format: string
	/**
	 * Materials of the product
	 */
	material: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
	 */
	printing_options?: string
}
export interface CustomizationItemAdded {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * The category of the page, added to the properties object.
	 */
	category: string
	/**
	 * Image url of the product in the list
	 */
	image_url: string
	/**
	 * This property reflects the type of item added in the design editor. There are 4 types of items that can be added: Text, Images, Shapes and QRCodes. To populate the property, you can be extract this straight from the CustomerCanvas event itself. Potential Values are "PlainTextItem", "BoundedTextItem", "ImageItem", "LineItem","RectangleItem", "EllipseItem", "BarcodeItem"
	 */
	item_type: string
	/**
	 * Product list being viewed
	 */
	list_id?: string
	/**
	 * Specifies the relevant section where the CTA interaction occurred., excluding page type.
	 */
	location: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web"
	 */
	platform: string
	/**
	 * Identifier of a customer's created customized product
	 */
	portfolio_item_id: string
	/**
	 * Position of product in the list
	 */
	position: number
	/**
	 * Product Price displayed in the list
	 */
	price: number
	/**
	 * Database ID of the product/Product ID displayed in the list
	 */
	product_id: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity: number
	/**
	 * Indicates whether the product selected is a sample (true/false)
	 */
	sample: boolean
	/**
	 * Product SKU displayed in the list
	 */
	sku: string
	/**
	 * URL of the product page for product in the list
	 */
	url: string
	/**
	 * Product Variant displayed in the list
	 */
	variant: VariantItem2[]
}
export interface VariantItem3 {
	/**
	 * The color of the product
	 */
	color: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.
	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
	 */
	format: string
	/**
	 * Materials of the product
	 */
	material: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
	 */
	printing_options?: string
}
export interface CustomizationItemChanged {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * The category of the page, added to the properties object.
	 */
	category: string
	/**
	 * This property is an array of strings and reflects the customization that a user applies to an existing element within the design.
	 */
	change_type: string
	count_of_changes: number
	/**
	 * This property reflects the type of item added in the design editor. There are 4 types of items that can be added: Text, Images, Shapes and QRCodes. To populate the property, you can be extract this straight from the CustomerCanvas event itself. Potential Values are "PlainTextItem", "BoundedTextItem", "ImageItem", "LineItem","RectangleItem", "EllipseItem", "BarcodeItem"
	 */
	item_type: string
	/**
	 * Specifies the relevant section where the CTA interaction occurred., excluding page type.
	 */
	location: string
	/**
	 * Product Name displayed in the list
	 */
	name?: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web"
	 */
	platform: string
	/**
	 * Identifier of a customer's created customized product
	 */
	portfolio_item_id: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Product Price displayed in the list
	 */
	price?: number
	/**
	 * Database ID of the product/Product ID displayed in the list
	 */
	product_id: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity?: number
	/**
	 * Indicates whether the product selected is a sample (true/false)
	 */
	sample: boolean
	/**
	 * Product SKU displayed in the list
	 */
	sku: string
	/**
	 * URL of the product page for product in the list
	 */
	url: string
	/**
	 * Product Variant displayed in the list
	 */
	variant: VariantItem3[]
}
export interface VariantItem4 {
	color: string
	foil_color?: string
	format: string
	material: string
	printing_options?: string
}
export interface CustomizationItemRemoved {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * The category of the page, added to the properties object.
	 */
	category: string
	/**
	 * Image url of the product in the list
	 */
	image_url: string
	item_type: string
	/**
	 * Product list being viewed
	 */
	list_id?: string
	location: string
	/**
	 * Product Name displayed in the list
	 */
	name: string
	platform: string
	portfolio_item_id: string
	/**
	 * Position of product in the list
	 */
	position: number
	/**
	 * Product Price displayed in the list
	 */
	price?: number
	/**
	 * Database ID of the product/Product ID displayed in the list
	 */
	product_id?: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity: number
	sample: boolean
	/**
	 * Product SKU displayed in the list
	 */
	sku: string
	/**
	 * URL of the product page for product in the list
	 */
	url: string
	/**
	 * Product Variant displayed in the list
	 */
	variant: VariantItem4[]
}
export interface VariantItem5 {
	/**
	 * The color of the product
	 */
	color: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.
	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
	 */
	format: string
	/**
	 * Materials of the product
	 */
	material: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
	 */
	printing_options?: string
}
export interface CustomizationReviewInitialized {
	/**
	 * Specifies the brand source - either WW or TK - within Paper, distinguishing the origin for each individual event.
	 */
	affiliation: string
	/**
	 * Reflects product category, e.g. Holiday Cards, Save the Dates, Thank Yous, Invitations, Ceremony & Reception
	 */
	category: string
	/**
	 * Whether a product customization has a design note. If yes, it does. if no, it doesn't
	 */
	has_design_notes?: boolean
	/**
	 * Specifies the relevant section where the CTA interaction occurred, excluding page type
	 */
	location: string
	/**
	 * Name of product (Forever Wedding Invitations by Vera Wang)
	 */
	name: string
	/**
	 * Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web
	 */
	platform: string
	/**
	 * Identifier of a customer's created customized product
	 */
	portfolio_item_id: string
	/**
	 * Unit Price of the product in dollars
	 */
	price?: number
	/**
	 * ID associated with the specific product
	 */
	product_id: string
	/**
	 * Quantity (i.e. number of units) of a product selected by the user.
	 */
	quantity?: number
	/**
	 * Indicates whether the product selected is a sample (true/false)
	 */
	sample: boolean
	/**
	 * Internal code as set up in the product model. 

For example, sku --> WINENV-0590-BLU-REC
https://docs.google.com/spreadsheets/d/1fjPDSGsFbAOZmGRwg2CaCEDtsYnPVx4raWRraKWoJ-4/edit#gid=1912960747
	 */
	sku: string
	/**
	 * Name of the paper surface that it's being customized
	 */
	surface?: string
	/**
	 * URL of the product page without any query parameters
	 */
	url: string
	/**
	 * Describes the attributes of the sku
	 */
	variant: VariantItem5[]
}
export interface CustomizationSavedForReview {
	/**
	 * Brand of the product
	 */
	brand?: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id?: string
	/**
	 * Product category being viewed
	 */
	category?: string
	/**
	 * The color of the product
	 */
	color?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	format?: string
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Name of the product
	 */
	name?: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Price of the product
	 */
	price: number
	/**
	 * Which printing option did the customer specify?
	 */
	printing_options?: string
	/**
	 * Database ID of the product
	 */
	product_id: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * URL of the product page for product
	 */
	url?: string
	/**
	 * Variant of the product
	 */
	variant?: string
}
export interface DraftCreated {
	category?: string
	draftId?: string
	platform?: string
	product?: string
}
export interface VariantItem6 {
	/**
	 * The color of the product
	 */
	color: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.
	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
	 */
	format: string
	/**
	 * Materials of the product
	 */
	material: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
	 */
	printing_options?: string
}
export interface DraftDeleted {
	/**
	 * Specifies the relevant section where the CTA interaction occurred, excluding page type
	 */
	Location?: string
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * The category of the page, added to the properties object.
	 */
	category: string
	/**
	 * The identifier for a customer's saved draft
	 */
	draft_id: string
	/**
	 * Whether a product customization has a design note. If yes, it does. if no, it doesn't
	 */
	has_design_notes?: boolean
	/**
	 * Product Name displayed in the list
	 */
	name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web"
	 */
	platform: string
	/**
	 * Identifier of a customer's created customized product
	 */
	portfolioItemId: string
	/**
	 * Product Price displayed in the list
	 */
	price?: number
	/**
	 * Database ID of the product/Product ID displayed in the list
	 */
	product_id: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity?: number
	/**
	 * Indicates whether the product selected is a sample (true/false)
	 */
	sample: boolean
	/**
	 * Product SKU displayed in the list
	 */
	sku: string
	/**
	 * Name of the paper surface that it's being customized
	 */
	surface?: string
	/**
	 * URL of the product page for product in the list
	 */
	url: string
	/**
	 * Product Variant displayed in the list
	 */
	variant: VariantItem6[]
}
export interface VariantItem7 {
	/**
	 * The color of the product
	 */
	color: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.
	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
	 */
	format: string
	/**
	 * Materials of the product
	 */
	material: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
	 */
	printing_options?: string
}
export interface DraftsItem {
	/**
	 * Image url of the product in the list
	 */
	image_url: string
	/**
	 * Product Name displayed in the list
	 */
	name: string
	/**
	 * Position of product in the list
	 */
	position: number
	/**
	 * Product Price displayed in the list
	 */
	price?: number
	/**
	 * Database ID of the product/Product ID displayed in the list
	 */
	product_id: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity?: number
	/**
	 * Indicates whether the product selected is a sample (true/false)
	 */
	sample: boolean
	/**
	 * Product SKU displayed in the list
	 */
	sku: string
	/**
	 * URL of the product page for product in the list
	 */
	url: string
	/**
	 * Product Variant displayed in the list
	 */
	variant: VariantItem7[]
}
export interface DraftListViewed {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * Category of the page
	 */
	category?: string
	drafts: DraftsItem[]
	/**
	 * Product list being viewed
	 */
	list_id: string
	/**
	 * The category of the page, added to the properties object.
	 */
	platform: string
}
export interface DraftUpdated {
	category?: string
	draftId?: string
	platform?: string
	product?: string
}
export interface FooterInteraction {
	/**
	 * Custom Agent: web
	 */
	platform?: string
	/**
	 * TK product: paper
	 */
	product?: string
	/**
	 * Footer Element selection
	 */
	selection?: string
}
export interface InformationEntered {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id: string
	information_type: string
	platform: string
	/**
	 * String representing the shipping method chosen
	 */
	shipping_method: string
}
export interface LogIn {
	platform?: string
	product?: string
	reason?: string
	selection?: string
}
export interface LogInFormDisplayed {
	/**
	 * Type of flow: proactive
	 */
	flowType?: string
	/**
	 * Custom Agent: web
	 */
	platform?: string
	/**
	 * TK product: paper
	 */
	product?: string
	/**
	 * Reason and location
	 */
	reason?: string
	selection?: string
}
export interface MenuInteraction {
	/**
	 * Menu Element selection
	 */
	categoryName?: string
	/**
	 * Custom Agent: web
	 */
	platform?: string
	/**
	 * TK product: paper
	 */
	product?: string
	/**
	 * Menu Element selection or dropdown element selection
	 */
	selection?: string
	/**
	 * Subnav
	 */
	source?: string
}
export interface MessageShown {
	/**
	 * Location on the page where the message is shown
	 */
	content?: string
	location?: string
	title?: string
	type?: string
}
export interface NewRegistration {
	platform?: string
	product?: string
	reason?: string
	selection?: string
}
export interface ProductsItem3 {
	/**
	 * Product Brand displayed in the list
	 */
	brand?: string
	/**
	 * Product Category displayed in the list
	 */
	category?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	format?: string
	/**
	 * Image url of the product in the list
	 */
	image_url?: string
	/**
	 * Product Name displayed in the list
	 */
	name?: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Product Price displayed in the list
	 */
	price?: number
	/**
	 * Product ID displayed in the list
	 */
	product_id: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity?: number
	/**
	 * Product SKU displayed in the list
	 */
	sku?: string
	/**
	 * URL of the product page for product in the list
	 */
	url?: string
	/**
	 * Product Variant displayed in the list
	 */
	variant?: string
}
export interface OrderCancelled {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Total discount associated with the transaction
	 */
	discount?: number
	/**
	 * Order/transaction ID
	 */
	order_id: string
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem3[]
	/**
	 * Revenue ($) associated with the transaction
	 */
	revenue: number
	/**
	 * Shipping cost associated with the transaction
	 */
	shipping?: number
	/**
	 * Total tax associated with the transaction
	 */
	tax?: number
	/**
	 * Revenue with discounts and coupons added
	 */
	total?: number
}
export interface ProductsItem4 {
	/**
	 * Product Brand displayed in the list
	 */
	brand?: string
	/**
	 * Product Category displayed in the list
	 */
	category?: string
	/**
	 * The color of the product
	 */
	color?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	format?: string
	/**
	 * Image url of the product in the list
	 */
	image_url?: string
	/**
	 * The material used to make the product item
	 */
	material?: any[]
	/**
	 * Product Name displayed in the list
	 */
	name?: string
	/**
	 * Product notes added by user
	 */
	notes?: boolean
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Product Price displayed in the list
	 */
	price?: number
	/**
	 * Which printing option did the customer specify?
	 */
	printing_options?: string
	/**
	 * Product ID displayed in the list
	 */
	product_id: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity?: number
	/**
	 * Product type is sample order
	 */
	sample?: boolean
	/**
	 * Product SKU displayed in the list
	 */
	sku?: string
	/**
	 * URL of the product page for product in the list
	 */
	url?: string
	/**
	 * Product Variant displayed in the list
	 */
	variant?: string
}
export interface OrderCompleted {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Total discount associated with the transaction
	 */
	discount?: number
	/**
	 * User email (temporary workaround, use identify() instead)
	 */
	email?: string
	/**
	 * Order/transaction ID
	 */
	order_id: string
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem4[]
	/**
	 * Revenue ($) associated with the transaction
	 */
	revenue: number
	/**
	 * Shipping cost associated with the transaction
	 */
	shipping?: number
	/**
	 * Total tax associated with the transaction
	 */
	tax?: number
	/**
	 * Revenue with discounts and coupons added
	 */
	total?: number
}
export interface ProductsItem5 {
	/**
	 * Product Brand displayed in the list
	 */
	brand?: string
	/**
	 * Product Category displayed in the list
	 */
	category?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	format?: string
	/**
	 * Image url of the product in the list
	 */
	image_url?: string
	/**
	 * Product Name displayed in the list
	 */
	name?: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Product Price displayed in the list
	 */
	price?: number
	/**
	 * Product ID displayed in the list
	 */
	product_id: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity?: number
	/**
	 * Product SKU displayed in the list
	 */
	sku?: string
	/**
	 * URL of the product page for product in the list
	 */
	url?: string
	/**
	 * Product Variant displayed in the list
	 */
	variant?: string
}
export interface OrderRefunded {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Total discount associated with the transaction
	 */
	discount?: number
	/**
	 * Order/transaction ID
	 */
	order_id: string
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem5[]
	/**
	 * Revenue ($) associated with the transaction
	 */
	revenue: number
	/**
	 * Shipping cost associated with the transaction
	 */
	shipping?: number
	/**
	 * Total tax associated with the transaction
	 */
	tax?: number
	/**
	 * Revenue with discounts and coupons added
	 */
	total?: number
}
export interface ProductsItem6 {
	/**
	 * Product Brand displayed in the list
	 */
	brand?: string
	/**
	 * Product Category displayed in the list
	 */
	category?: string
	/**
	 * The color of the product
	 */
	color?: Record<string, any>
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	format?: string
	/**
	 * Image url of the product in the list
	 */
	image_url?: string
	/**
	 * Product Name displayed in the list
	 */
	name?: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Product Price displayed in the list
	 */
	price?: number
	/**
	 * Product ID displayed in the list
	 */
	product_id: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity?: number
	/**
	 * Product SKU displayed in the list
	 */
	sku?: string
	/**
	 * URL of the product page for product in the list
	 */
	url?: string
	/**
	 * Product Variant displayed in the list
	 */
	variant?: string
}
export interface OrderUpdated {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Currency code associated with the transaction
	 */
	currency?: string
	/**
	 * Total discount associated with the transaction
	 */
	discount?: number
	/**
	 * Order/transaction ID
	 */
	order_id: string
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem6[]
	/**
	 * Revenue ($) associated with the transaction
	 */
	revenue: number
	/**
	 * Shipping cost associated with the transaction
	 */
	shipping?: number
	/**
	 * Total tax associated with the transaction
	 */
	tax?: number
	/**
	 * Revenue with discounts and coupons added
	 */
	total?: number
}
export interface PageViewed {
	''?: Record<string, any>
	path?: string
	referrer?: string
	resolution?: string
	search?: string
	title?: string
	url?: string
}
export interface PaymentInfoEntered {
	/**
	 * Checkout transaction ID
	 */
	checkout_id?: string
	/**
	 * Order/transaction ID
	 */
	order_id: string
	/**
	 * String representing the payment method chosen
	 */
	payment_method?: string
	/**
	 * String representing the shipping method chosen
	 */
	shipping_method?: string
	/**
	 * Number representing a step in the checkout process
	 */
	step?: number
}
export interface VariantItem8 {
	/**
	 * The color of the product
	 */
	color: string
	flap_type?: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.
	 */
	foil_color?: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
	 */
	format: string
	/**
	 * Materials of the product
	 */
	material: string
	paper_color?: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
	 */
	printing_options?: string
}
export interface ProductAdded {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id: string
	/**
	 * Product category being viewed
	 */
	category: string
	/**
	 * Whether a product customization has a design note. If yes, it does. if no, it doesn't
	 */
	has_design_notes?: boolean
	/**
	 * Image url of the product
	 */
	image_url: string
	/**
	 * Name of the product
	 */
	name: string
	/**
	 * The platform of the application where the event fired
	 */
	platform: string
	/**
	 * Position of product in the list
	 */
	position: string
	/**
	 * Price of the product
	 */
	price: number
	/**
	 * Database ID of the product
	 */
	product_id: string
	/**
	 * Quantity of the product
	 */
	quantity: number
	/**
	 * Is the product a sample?
	 */
	sample: boolean
	/**
	 * Sku of the product
	 */
	sku: string
	/**
	 * URL of the product page for product
	 */
	url: string
	/**
	 * Product Variant displayed in the list
	 */
	variant: VariantItem8[]
}
export interface ProductAddedToWishlist {
	/**
	 * Brand of the product
	 */
	brand?: string
	/**
	 * Product category being viewed
	 */
	category?: string
	/**
	 * The color of the product
	 */
	color?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	format?: string
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Materials of the product
	 */
	material?: any[]
	/**
	 * Name of the product
	 */
	name?: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Price of the product
	 */
	price: number
	/**
	 * Which printing option did the customer specify?
	 */
	printing_options?: string
	/**
	 * Database ID of the product
	 */
	product_id: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * URL of the product page for product
	 */
	url?: string
	/**
	 * Variant of the product
	 */
	variant?: string
	/**
	 * Wishlist ID to which the product was added
	 */
	wishlist_id: string
	/**
	 * Wishlist name to which the product was added
	 */
	wishlist_name?: string
}
export interface ProductClicked {
	/**
	 * Brand of the product
	 */
	brand?: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id?: string
	/**
	 * Product category being viewed
	 */
	category?: string
	/**
	 * The color of the product
	 */
	color?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	format?: string
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Location where the product was clicked
	 */
	location?: string
	/**
	 * Materials of the product
	 */
	material?: any[]
	/**
	 * Name of the product
	 */
	name?: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Price of the product
	 */
	price: number
	/**
	 * Which printing option did the customer specify?
	 */
	printing_options?: string
	/**
	 * Database ID of the product
	 */
	product_id: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * URL of the product page for product
	 */
	url?: string
	/**
	 * Variant of the product
	 */
	variant?: string
}
export interface ProductClickedQuickLook {
	/**
	 * Brand of the product
	 */
	brand?: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id?: string
	/**
	 * Product category being viewed
	 */
	category?: string
	/**
	 * The color of the product
	 */
	color?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	format?: string
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Location where the product was clicked
	 */
	location?: string
	/**
	 * Materials of the product
	 */
	material?: any[]
	/**
	 * Name of the product
	 */
	name?: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Price of the product
	 */
	price: number
	/**
	 * Which printing option did the customer specify?
	 */
	printing_options?: string
	/**
	 * Database ID of the product
	 */
	product_id: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * URL of the product page for product
	 */
	url?: string
	/**
	 * Variant of the product
	 */
	variant?: string
}
export interface ProductConfigured {
	/**
	 * Brand of the product
	 */
	brand?: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id?: string
	/**
	 * Product category being viewed
	 */
	category?: string
	/**
	 * The color of the product
	 */
	color?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	format?: string
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Materials of the product
	 */
	material?: any[]
	/**
	 * Name of the product
	 */
	name?: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Price of the product
	 */
	price: number
	/**
	 * Which printing option did the customer specify?
	 */
	printing_options?: string
	/**
	 * Database ID of the product
	 */
	product_id: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * URL of the product page for product
	 */
	url?: string
	/**
	 * Variant of the product
	 */
	variant?: string
}
export interface FiltersItem {
	/**
	 * Clicked element
	 */
	click?: string
	/**
	 * ID of the filter type being used
	 */
	type?: string
	/**
	 * ID of the selection chosen
	 */
	value?: string
}
export interface ProductsItem7 {
	/**
	 * Product Brand displayed in the list
	 */
	brand?: string
	/**
	 * Product Category displayed in the list
	 */
	category?: string
	/**
	 * The color of the product
	 */
	color?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	format?: string
	/**
	 * Image url of the product in the list
	 */
	image_url?: string
	/**
	 * Materials of the product
	 */
	material?: any[]
	/**
	 * Product Name displayed in the list
	 */
	name?: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Product Price displayed in the list
	 */
	price?: number
	/**
	 * Product ID displayed in the list
	 */
	product_id: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity?: number
	/**
	 * Product SKU displayed in the list
	 */
	sku?: string
	/**
	 * URL of the product page for product in the list
	 */
	url?: string
	/**
	 * Product Variant displayed in the list
	 */
	variant?: string
}
export interface SortsItem {
	/**
	 * ID of the sort type being used
	 */
	type?: string
	/**
	 * ID of the selection type being used
	 */
	value?: string
}
export interface ProductListFiltered {
	/**
	 * Product category being viewed
	 */
	category?: string
	/**
	 * Product filters that the customer is using
	 */
	filters?: FiltersItem[]
	/**
	 * Product list being viewed
	 */
	list_id?: string
	/**
	 * Location where Product List Viewed was triggered
	 */
	location?: string
	/**
	 * Which printing option did the customer specify?
	 */
	printing_options?: string
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem7[]
	/**
	 * Product sorting the customer is using
	 */
	sorts?: SortsItem[]
}
export interface ProductsItem8 {
	/**
	 * Product Brand displayed in the list
	 */
	brand?: string
	/**
	 * Product Category displayed in the list
	 */
	category?: string
	/**
	 * The color of the product
	 */
	color?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	format?: string
	/**
	 * Image url of the product in the list
	 */
	image_url?: string
	/**
	 * Materials of the product
	 */
	material?: any[]
	/**
	 * Product Name displayed in the list
	 */
	name?: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Product Price displayed in the list
	 */
	price?: number
	/**
	 * Product ID displayed in the list
	 */
	product_id: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity?: number
	/**
	 * Product SKU displayed in the list
	 */
	sku?: string
	/**
	 * URL of the product page for product in the list
	 */
	url?: string
	/**
	 * Product Variant displayed in the list
	 */
	variant?: string
}
export interface ProductListViewed {
	/**
	 * Product category being viewed
	 */
	category?: string
	/**
	 * Product list being viewed
	 */
	list_id?: string
	/**
	 * Location where Product List Viewed was triggered
	 */
	location?: string
	/**
	 * Which printing option did the customer specify?
	 */
	printing_options?: string
	/**
	 * Products displayed in the product list
	 */
	products: ProductsItem8[]
}
export interface VariantItem9 {
	/**
	 * The color of the product
	 */
	color: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.
	 */
	foil_color: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
	 */
	format: string
	/**
	 * Materials of the product
	 */
	material: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
	 */
	printing_options: string
}
export interface ProductMoved {
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id: string
	/**
	 * The category of the page, added to the properties object.
	 */
	category: string
	/**
	 * Shopping cart ID - this is a replicate of the cart_id field
	 */
	checkout_id: string
	/**
	 * Whether a product customization has a design note. If yes, it does. if no, it doesn't
	 */
	has_design_notes: boolean
	/**
	 * Image url of the product in the list
	 */
	image_url: string
	/**
	 * Product Name displayed in the list
	 */
	name: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web"
	 */
	platform: string
	/**
	 * portfolioItemId
	 */
	portfolioItemId: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Product Price displayed in the list
	 */
	price?: number
	/**
	 * Database ID of the product/Product ID displayed in the list
	 */
	product_id: string
	/**
	 * Product quantity displayed in the list
	 */
	quantity?: number
	/**
	 * Indicates whether the product selected is a sample (true/false)
	 */
	sample: boolean
	/**
	 * Product SKU displayed in the list
	 */
	sku: string
	/**
	 * Name of the paper surface that it's being customized
	 */
	surface?: string
	/**
	 * URL of the product page for product in the list
	 */
	url: string
	/**
	 * Total value of the product after quantity
	 */
	value: string
	/**
	 * Product Variant displayed in the list
	 */
	variant: VariantItem9[]
}
export interface VariantItem10 {
	/**
	 * The color of the product
	 */
	color: string
	flap_type?: string
	/**
	 * Foil Color: Tracks chosen foil options - rose, gold, silver.
	 */
	foil_color: string
	/**
	 * Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
	 */
	format: string
	/**
	 * Materials of the product
	 */
	material: string
	paper_color?: string
	/**
	 * Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
	 */
	printing_options?: string
}
export interface ProductRemoved {
	/**
	 * Store or affiliation from which this transaction occurred
	 */
	affiliation: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id: string
	/**
	 * The category of the page, added to the properties object.
	 */
	category: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Whether a product customization has a design note. If yes, it does. if no, it doesn't
	 */
	has_design_notes: boolean
	/**
	 * Image url of the product in the list
	 */
	image_url: string
	/**
	 * Name of the product
	 */
	name?: string
	/**
	 * "Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web"
	 */
	platform: string
	/**
	 * Position of product in the list
	 */
	position: number
	/**
	 * Price of the product
	 */
	price: number
	/**
	 * Database ID of the product
	 */
	product_id: string
	/**
	 * Quantity of the product
	 */
	quantity: number
	/**
	 * Sku of the product
	 */
	sku: string
	/**
	 * URL of the product page for product
	 */
	url: string
	/**
	 * Product Variant displayed in the list
	 */
	variant: VariantItem10[]
}
export interface ProductRemovedFromWishlist {
	/**
	 * Brand of the product
	 */
	brand?: string
	/**
	 * Product category being viewed
	 */
	category?: string
	/**
	 * The color of the product
	 */
	color?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	format?: string
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Materials of the product
	 */
	material?: any[]
	/**
	 * Name of the product
	 */
	name?: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Price of the product
	 */
	price: number
	/**
	 * Which printing option did the customer specify?
	 */
	printing_options?: string
	/**
	 * Database ID of the product
	 */
	product_id: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * URL of the product page for product
	 */
	url?: string
	/**
	 * Variant of the product
	 */
	variant?: string
	/**
	 * Wishlist ID to which the product was added
	 */
	wishlist_id: string
	/**
	 * Wishlist name to which the product was added
	 */
	wishlist_name?: string
}
export interface ProductReviewed {
	format?: string
	/**
	 * Which printing option did the customer specify?
	 */
	printing_options?: string
	/**
	 * Database ID of the product
	 */
	product_id: string
	/**
	 * Star rating
	 */
	rating?: number
	/**
	 * Content of the review
	 */
	review_body?: string
	/**
	 * ID of the review posted
	 */
	review_id?: string
}
export interface ProductShared {
	/**
	 * Brand of the product
	 */
	brand?: string
	/**
	 * Product category being viewed
	 */
	category?: string
	/**
	 * The color of the product
	 */
	color?: string
	format?: string
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Materials of the product
	 */
	material?: any[]
	/**
	 * Name of the product
	 */
	name?: string
	/**
	 * Price of the product
	 */
	price: number
	/**
	 * Which printing option did the customer specify?
	 */
	printing_options?: string
	/**
	 * Database ID of the product
	 */
	product_id: string
	/**
	 * Recipient of the sharing
	 */
	recipient?: string
	/**
	 * Message that the sender sent
	 */
	share_message?: string
	/**
	 * Method of sharing
	 */
	share_via: string
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * URL of the product page for product
	 */
	url?: string
	/**
	 * Variant of the product
	 */
	variant?: string
}
export interface ProductViewed {
	/**
	 * Brand of the product
	 */
	brand?: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id?: string
	/**
	 * Product category being viewed
	 */
	category?: string
	/**
	 * The color of the product
	 */
	color?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	format?: string
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Location where the product was clicked
	 */
	location?: string
	/**
	 * Materials of the product
	 */
	material?: any[]
	/**
	 * Name of the product
	 */
	name?: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Price of the product
	 */
	price: number
	/**
	 * Which printing option did the customer specify?
	 */
	printing_options?: string
	/**
	 * Database ID of the product
	 */
	product_id: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * URL of the product page for product
	 */
	url?: string
	/**
	 * Variant of the product
	 */
	variant?: string
}
export interface ProductsSearched {
	platform?: string
	/**
	 * Query the user searched with
	 */
	query?: string
	section?: string
	selection?: string
}
export interface PromotionClicked {
	/**
	 * Description of promotion's creative
	 */
	promotion_creative?: string
	/**
	 * ID of the promotion
	 */
	promotion_id?: string
	/**
	 * Name of the promotion
	 */
	promotion_name?: string
	/**
	 * Position of the promotion
	 */
	promotion_position?: string
}
export interface PromotionViewed {
	/**
	 * Description of promotion's creative
	 */
	promotion_creative?: string
	/**
	 * ID of the promotion
	 */
	promotion_id?: string
	/**
	 * Name of the promotion
	 */
	promotion_name?: string
	/**
	 * Position of the promotion
	 */
	promotion_position?: string
}
export interface RegistrationFormDisplayed {
	/**
	 * Type of flow: proactive
	 */
	flowType?: string
	/**
	 * Custom Agent: web
	 */
	platform?: string
	/**
	 * TK product: paper
	 */
	product?: string
	/**
	 * Reason and location
	 */
	reason?: string
	selection?: string
	/**
	 * Step
	 */
	stepCount?: number
	/**
	 * Step
	 */
	stepNumber?: number
}
export interface VeraWangLandingPageInteraction {
	/**
	 * Custom Agent: web
	 */
	platform?: string
	/**
	 * TK product: marketing-landing-pages
	 */
	product?: string
	/**
	 * Device resolution
	 */
	resolution?: string
	/**
	 * Area clicked
	 */
	selection?: string
	userDecisionArea?: string
}
export interface WishlistProductAddedToCart {
	/**
	 * Brand of the product
	 */
	brand?: string
	/**
	 * cart ID associated with the product displayed
	 */
	cart_id?: string
	/**
	 * Product category being viewed
	 */
	category?: string
	/**
	 * The color of the product
	 */
	color?: string
	/**
	 * Coupon code associated with the product
	 */
	coupon?: string
	/**
	 * Image url of the product
	 */
	image_url?: string
	/**
	 * Materials of the product
	 */
	material?: any[]
	/**
	 * Name of the product
	 */
	name?: string
	/**
	 * Position of product in the list
	 */
	position?: number
	/**
	 * Price of the product
	 */
	price: number
	/**
	 * Which printing option did the customer specify?
	 */
	printing_options?: string
	/**
	 * Database ID of the product
	 */
	product_id: string
	/**
	 * Quantity of the product
	 */
	quantity?: number
	/**
	 * Sku of the product
	 */
	sku?: string
	/**
	 * URL of the product page for product
	 */
	url?: string
	/**
	 * Variant of the product
	 */
	variant?: string
	/**
	 * Wishlist ID to which the product was added
	 */
	wishlist_id: string
	/**
	 * Wishlist name to which the product was added
	 */
	wishlist_name?: string
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.4.1',
			},
		},
	}
}

/**
 * @typedef ExperimentStarted
 * @property {string} Experiment name - the name of the variant into which the user was bucketed, for that experiment
 * @property {string} Variant name - the name of the experiment to which the user has been exposed
 */
/**
 * @typedef CtaClicked
 * @property {string} [category] -
 * @property {string} [element_clicked] -
 * @property {string} [location] -
 * @property {string} [platform] -
 * @property {string} [product] -
 * @property {string} [product_id] - Database ID of the product
 * @property {string} [reason] -
 */
/**
 * @typedef ProductsItem
 * @property {string} [format] -
 * @property {string} product_id - Product ID displayed in the list
 */
/**
 * @typedef CartShared
 * @property {string} [cart_id] - cart ID associated with the product displayed
 * @property {ProductsItem[]} products - Products displayed in the product list
 * @property {string} [recipient] - Recipient of the sharing
 * @property {string} [share_message] - Message that the sender sent
 * @property {string} share_via - Method of sharing
 */
/**
 * @typedef VariantItem
 * @property {string} color - The color of the product
 * @property {string} [flap_type] -
 * @property {string} foil_color - Foil Color: Tracks chosen foil options - rose, gold, silver.
 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
 * @property {string} material - Materials of the product
 * @property {string} [paper_color] -
 * @property {string} [printing_options] - Which printing option did the customer specify?
 */
/**
 * @typedef ProductsItem1
 * @property {string} category - Product Category displayed in the list
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {number} position - Position of product in the list
 * @property {number} price - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {number} quantity - Product quantity displayed in the list
 * @property {boolean} sample - Indicates whether the product selected is a sample (true/false)
 * @property {string} sku - Sku of the product being viewed
 * @property {string} url - URL of the product page
 * @property {VariantItem[]} variant - Variant of the product
 */
/**
 * @typedef CartViewed
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} cart_id - cart ID associated with the product displayed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {ProductsItem1[]} products - Products displayed in the product list
 * @property {string} value - Total value of the product after quantity
 */
/**
 * @typedef VariantItem1
 * @property {string} color - The color of the product
 * @property {string} [flap_type] -
 * @property {string} foil_color - Foil Color: Tracks chosen foil options - rose, gold, silver.
 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
 * @property {string} material - Materials of the product
 * @property {string} [paper_color] -
 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
 */
/**
 * @typedef ProductsItem2
 * @property {string} category - Product Category displayed in the list
 * @property {string} coupon - Coupon code associated with the product
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {string} [notes] -
 * @property {number} position - Position of product in the list
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {number} quantity - Product quantity displayed in the list
 * @property {string} sku - Product SKU displayed in the list
 * @property {string} url - URL of the product page for product in the list
 * @property {VariantItem1[]} variant - Product Variant displayed in the list
 */
/**
 * @typedef CheckoutStarted
 * @property {string} affiliation - Store or affiliation from which this transaction occurred 
 * @property {string} cart_id - cart ID associated with the product displayed
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} currency - Currency code associated with the transaction
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {string} order_id - Order/transaction ID
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web"
 * @property {ProductsItem2[]} products - Products displayed in the product list
 * @property {number} revenue - Revenue ($) associated with the transaction
 * @property {string} value - Total value of the product after quantity
 */
/**
 * @typedef CheckoutStepCompleted
 * @property {string} [checkout_id] - Checkout transaction ID
 * @property {string} [payment_method] - String representing the payment method chosen
 * @property {string} [shipping_method] - String representing the shipping method chosen
 * @property {number} [step] - Number representing a step in the checkout process
 */
/**
 * @typedef CheckoutStepViewed
 * @property {string} [checkout_id] - Checkout transaction ID
 * @property {string} [location] -
 * @property {string} [payment_method] - String representing the payment method chosen
 * @property {string} [platform] -
 * @property {string} [section] -
 * @property {string} [shipping_method] - String representing the shipping method chosen
 * @property {number} [step] - Number representing a step in the checkout process
 */
/**
 * @typedef CouponApplied
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} cart_id - cart ID associated with the product displayed
 * @property {string} coupon - Coupon code associated with the product
 * @property {string} coupon_name - Name of the coupon
 * @property {number} discount - Total discount associated with the transaction
 * @property {string} platform -
 */
/**
 * @typedef CouponDenied
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} cart_id - cart ID associated with the product displayed
 * @property {string} coupon - Coupon code associated with the product
 * @property {string} [coupon_name] - Name of the coupon
 * @property {string} platform -
 * @property {string} reason - Reason the coupon was denied
 */
/**
 * @typedef CouponEntered
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} cart_id - cart ID associated with the product displayed
 * @property {string} coupon - Coupon code associated with the product
 * @property {string} coupon_name - Name of the coupon
 * @property {string} platform -
 */
/**
 * @typedef CouponRemoved
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} cart_id - cart ID associated with the product displayed
 * @property {string} coupon - Coupon code associated with the product
 * @property {string} coupon_name - Name of the coupon
 * @property {number} discount - Total discount associated with the transaction
 * @property {string} platform -
 */
/**
 * @typedef CustomizationCancelled
 * @property {string} [brand] - Brand of the product
 * @property {string} [cart_id] - cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [color] - The color of the product
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [format] -
 * @property {string} [image_url] - Image url of the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} [printing_options] - Which printing option did the customer specify?
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef CustomizationInitialized
 * @property {boolean} [brand] - Brand of the product
 * @property {string} [cart_id] - cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [color] - The color of the product
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [format] -
 * @property {string} [image_url] - Image url of the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} [printing_options] - Which printing option did the customer specify?
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {boolean} [sample] -
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef CustomizationInteraction
 * @property {string} [brand] - Brand of the product
 * @property {string} [cart_id] - cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [color] - The color of the product
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [format] -
 * @property {string} [image_url] - Image url of the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} [printing_options] - Which printing option did the customer specify?
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef VariantItem2
 * @property {string} color - The color of the product
 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.
 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
 * @property {string} material - Materials of the product
 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
 */
/**
 * @typedef CustomizationItemAdded
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} category - The category of the page, added to the properties object.
 * @property {string} image_url - Image url of the product in the list
 * @property {string} item_type - This property reflects the type of item added in the design editor. There are 4 types of items that can be added: Text, Images, Shapes and QRCodes. To populate the property, you can be extract this straight from the CustomerCanvas event itself. Potential Values are "PlainTextItem", "BoundedTextItem", "ImageItem", "LineItem","RectangleItem", "EllipseItem", "BarcodeItem"
 * @property {string} [list_id] - Product list being viewed
 * @property {string} location - Specifies the relevant section where the CTA interaction occurred., excluding page type.
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web"
 * @property {string} portfolio_item_id - Identifier of a customer's created customized product
 * @property {number} position - Position of product in the list
 * @property {number} price - Product Price displayed in the list
 * @property {string} product_id - Database ID of the product/Product ID displayed in the list
 * @property {number} quantity - Product quantity displayed in the list
 * @property {boolean} sample - Indicates whether the product selected is a sample (true/false)
 * @property {string} sku - Product SKU displayed in the list
 * @property {string} url - URL of the product page for product in the list
 * @property {VariantItem2[]} variant - Product Variant displayed in the list
 */
/**
 * @typedef VariantItem3
 * @property {string} color - The color of the product
 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.
 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
 * @property {string} material - Materials of the product
 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
 */
/**
 * @typedef CustomizationItemChanged
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} category - The category of the page, added to the properties object.
 * @property {string} change_type - This property is an array of strings and reflects the customization that a user applies to an existing element within the design.
 * @property {number} count_of_changes - 
 * @property {string} item_type - This property reflects the type of item added in the design editor. There are 4 types of items that can be added: Text, Images, Shapes and QRCodes. To populate the property, you can be extract this straight from the CustomerCanvas event itself. Potential Values are "PlainTextItem", "BoundedTextItem", "ImageItem", "LineItem","RectangleItem", "EllipseItem", "BarcodeItem"
 * @property {string} location - Specifies the relevant section where the CTA interaction occurred., excluding page type.
 * @property {string} [name] - Product Name displayed in the list
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web"
 * @property {string} portfolio_item_id - Identifier of a customer's created customized product
 * @property {number} [position] - Position of product in the list
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} product_id - Database ID of the product/Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {boolean} sample - Indicates whether the product selected is a sample (true/false)
 * @property {string} sku - Product SKU displayed in the list
 * @property {string} url - URL of the product page for product in the list
 * @property {VariantItem3[]} variant - Product Variant displayed in the list
 */
/**
 * @typedef VariantItem4
 * @property {string} color -
 * @property {string} [foil_color] -
 * @property {string} format -
 * @property {string} material -
 * @property {string} [printing_options] -
 */
/**
 * @typedef CustomizationItemRemoved
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} category - The category of the page, added to the properties object.
 * @property {string} image_url - Image url of the product in the list
 * @property {string} item_type -
 * @property {string} [list_id] - Product list being viewed
 * @property {string} location -
 * @property {string} name - Product Name displayed in the list
 * @property {string} platform -
 * @property {string} portfolio_item_id -
 * @property {number} position - Position of product in the list
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} [product_id] - Database ID of the product/Product ID displayed in the list
 * @property {number} quantity - Product quantity displayed in the list
 * @property {boolean} sample -
 * @property {string} sku - Product SKU displayed in the list
 * @property {string} url - URL of the product page for product in the list
 * @property {VariantItem4[]} variant - Product Variant displayed in the list
 */
/**
 * @typedef VariantItem5
 * @property {string} color - The color of the product
 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.
 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
 * @property {string} material - Materials of the product
 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
 */
/**
 * @typedef CustomizationReviewInitialized
 * @property {string} affiliation - Specifies the brand source - either WW or TK - within Paper, distinguishing the origin for each individual event.
 * @property {string} category - Reflects product category, e.g. Holiday Cards, Save the Dates, Thank Yous, Invitations, Ceremony & Reception
 * @property {boolean} [has_design_notes] - Whether a product customization has a design note. If yes, it does. if no, it doesn't 
 * @property {string} location - Specifies the relevant section where the CTA interaction occurred, excluding page type 
 * @property {string} name - Name of product (Forever Wedding Invitations by Vera Wang)
 * @property {string} platform - Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web
 * @property {string} portfolio_item_id - Identifier of a customer's created customized product
 * @property {number} [price] - Unit Price of the product in dollars
 * @property {string} product_id - ID associated with the specific product
 * @property {number} [quantity] - Quantity (i.e. number of units) of a product selected by the user.
 * @property {boolean} sample - Indicates whether the product selected is a sample (true/false)
 * @property {string} sku - Internal code as set up in the product model. 

For example, sku --> WINENV-0590-BLU-REC
https://docs.google.com/spreadsheets/d/1fjPDSGsFbAOZmGRwg2CaCEDtsYnPVx4raWRraKWoJ-4/edit#gid=1912960747
 * @property {string} [surface] - Name of the paper surface that it's being customized 
 * @property {string} url - URL of the product page without any query parameters
 * @property {VariantItem5[]} variant - Describes the attributes of the sku
 */
/**
 * @typedef CustomizationSavedForReview
 * @property {string} [brand] - Brand of the product
 * @property {string} [cart_id] - cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [color] - The color of the product
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [format] -
 * @property {string} [image_url] - Image url of the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} [printing_options] - Which printing option did the customer specify?
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef DraftCreated
 * @property {string} [category] -
 * @property {string} [draftId] -
 * @property {string} [platform] -
 * @property {string} [product] -
 */
/**
 * @typedef VariantItem6
 * @property {string} color - The color of the product
 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.
 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
 * @property {string} material - Materials of the product
 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
 */
/**
 * @typedef DraftDeleted
 * @property {string} [Location] - Specifies the relevant section where the CTA interaction occurred, excluding page type 
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} category - The category of the page, added to the properties object.
 * @property {string} draft_id - The identifier for a customer's saved draft
 * @property {boolean} [has_design_notes] - Whether a product customization has a design note. If yes, it does. if no, it doesn't 
 * @property {string} name - Product Name displayed in the list
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web"
 * @property {string} portfolioItemId - Identifier of a customer's created customized product
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} product_id - Database ID of the product/Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {boolean} sample - Indicates whether the product selected is a sample (true/false)
 * @property {string} sku - Product SKU displayed in the list
 * @property {string} [surface] - Name of the paper surface that it's being customized 
 * @property {string} url - URL of the product page for product in the list
 * @property {VariantItem6[]} variant - Product Variant displayed in the list
 */
/**
 * @typedef VariantItem7
 * @property {string} color - The color of the product
 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.
 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
 * @property {string} material - Materials of the product
 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
 */
/**
 * @typedef DraftsItem
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {number} position - Position of product in the list
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} product_id - Database ID of the product/Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {boolean} sample - Indicates whether the product selected is a sample (true/false)
 * @property {string} sku - Product SKU displayed in the list
 * @property {string} url - URL of the product page for product in the list
 * @property {VariantItem7[]} variant - Product Variant displayed in the list
 */
/**
 * @typedef DraftListViewed
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} [category] - Category of the page
 * @property {DraftsItem[]} drafts -
 * @property {string} list_id - Product list being viewed
 * @property {string} platform - The category of the page, added to the properties object.
 */
/**
 * @typedef DraftUpdated
 * @property {string} [category] -
 * @property {string} [draftId] -
 * @property {string} [platform] -
 * @property {string} [product] -
 */
/**
 * @typedef FooterInteraction
 * @property {string} [platform] - Custom Agent: web
 * @property {string} [product] - TK product: paper
 * @property {string} [selection] - Footer Element selection
 */
/**
 * @typedef InformationEntered
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} cart_id - cart ID associated with the product displayed
 * @property {string} information_type -
 * @property {string} platform -
 * @property {string} shipping_method - String representing the shipping method chosen
 */
/**
 * @typedef LogIn
 * @property {string} [platform] -
 * @property {string} [product] -
 * @property {string} [reason] -
 * @property {string} [selection] -
 */
/**
 * @typedef LogInFormDisplayed
 * @property {string} [flowType] - Type of flow: proactive
 * @property {string} [platform] - Custom Agent: web
 * @property {string} [product] - TK product: paper
 * @property {string} [reason] - Reason and location
 * @property {string} [selection] -
 */
/**
 * @typedef MenuInteraction
 * @property {string} [categoryName] - Menu Element selection
 * @property {string} [platform] - Custom Agent: web
 * @property {string} [product] - TK product: paper
 * @property {string} [selection] - Menu Element selection or dropdown element selection
 * @property {string} [source] - Subnav
 */
/**
 * @typedef MessageShown
 * @property {string} [content] - Location on the page where the message is shown
 * @property {string} [location] -
 * @property {string} [title] -
 * @property {string} [type] -
 */
/**
 * @typedef NewRegistration
 * @property {string} [platform] -
 * @property {string} [product] -
 * @property {string} [reason] -
 * @property {string} [selection] -
 */
/**
 * @typedef ProductsItem3
 * @property {string} [brand] - Product Brand displayed in the list
 * @property {string} [category] - Product Category displayed in the list
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [format] -
 * @property {string} [image_url] - Image url of the product in the list
 * @property {string} [name] - Product Name displayed in the list
 * @property {number} [position] - Position of product in the list
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} [sku] - Product SKU displayed in the list
 * @property {string} [url] - URL of the product page for product in the list
 * @property {string} [variant] - Product Variant displayed in the list
 */
/**
 * @typedef OrderCancelled
 * @property {string} [affiliation] - Store or affiliation from which this transaction occurred
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {string} order_id - Order/transaction ID
 * @property {ProductsItem3[]} products - Products displayed in the product list
 * @property {number} revenue - Revenue ($) associated with the transaction
 * @property {number} [shipping] - Shipping cost associated with the transaction
 * @property {number} [tax] - Total tax associated with the transaction
 * @property {number} [total] - Revenue with discounts and coupons added
 */
/**
 * @typedef ProductsItem4
 * @property {string} [brand] - Product Brand displayed in the list
 * @property {string} [category] - Product Category displayed in the list
 * @property {string} [color] - The color of the product
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [format] -
 * @property {string} [image_url] - Image url of the product in the list
 * @property {any[]} [material] - The material used to make the product item
 * @property {string} [name] - Product Name displayed in the list
 * @property {boolean} [notes] - Product notes added by user
 * @property {number} [position] - Position of product in the list
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} [printing_options] - Which printing option did the customer specify?
 * @property {string} product_id - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {boolean} [sample] - Product type is sample order
 * @property {string} [sku] - Product SKU displayed in the list
 * @property {string} [url] - URL of the product page for product in the list
 * @property {string} [variant] - Product Variant displayed in the list
 */
/**
 * @typedef OrderCompleted
 * @property {string} [affiliation] - Store or affiliation from which this transaction occurred
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {string} [email] - User email (temporary workaround, use identify() instead)
 * @property {string} order_id - Order/transaction ID
 * @property {ProductsItem4[]} products - Products displayed in the product list
 * @property {number} revenue - Revenue ($) associated with the transaction
 * @property {number} [shipping] - Shipping cost associated with the transaction
 * @property {number} [tax] - Total tax associated with the transaction
 * @property {number} [total] - Revenue with discounts and coupons added
 */
/**
 * @typedef ProductsItem5
 * @property {string} [brand] - Product Brand displayed in the list
 * @property {string} [category] - Product Category displayed in the list
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [format] -
 * @property {string} [image_url] - Image url of the product in the list
 * @property {string} [name] - Product Name displayed in the list
 * @property {number} [position] - Position of product in the list
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} [sku] - Product SKU displayed in the list
 * @property {string} [url] - URL of the product page for product in the list
 * @property {string} [variant] - Product Variant displayed in the list
 */
/**
 * @typedef OrderRefunded
 * @property {string} [affiliation] - Store or affiliation from which this transaction occurred
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {string} order_id - Order/transaction ID
 * @property {ProductsItem5[]} products - Products displayed in the product list
 * @property {number} revenue - Revenue ($) associated with the transaction
 * @property {number} [shipping] - Shipping cost associated with the transaction
 * @property {number} [tax] - Total tax associated with the transaction
 * @property {number} [total] - Revenue with discounts and coupons added
 */
/**
 * @typedef ProductsItem6
 * @property {string} [brand] - Product Brand displayed in the list
 * @property {string} [category] - Product Category displayed in the list
 * @property {Record<string, any>} [color] - The color of the product
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [format] -
 * @property {string} [image_url] - Image url of the product in the list
 * @property {string} [name] - Product Name displayed in the list
 * @property {number} [position] - Position of product in the list
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} [sku] - Product SKU displayed in the list
 * @property {string} [url] - URL of the product page for product in the list
 * @property {string} [variant] - Product Variant displayed in the list
 */
/**
 * @typedef OrderUpdated
 * @property {string} [affiliation] - Store or affiliation from which this transaction occurred
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [currency] - Currency code associated with the transaction
 * @property {number} [discount] - Total discount associated with the transaction
 * @property {string} order_id - Order/transaction ID
 * @property {ProductsItem6[]} products - Products displayed in the product list
 * @property {number} revenue - Revenue ($) associated with the transaction
 * @property {number} [shipping] - Shipping cost associated with the transaction
 * @property {number} [tax] - Total tax associated with the transaction
 * @property {number} [total] - Revenue with discounts and coupons added
 */
/**
 * @typedef PageViewed
 * @property {Record<string, any>} [] -
 * @property {string} [path] -
 * @property {string} [referrer] -
 * @property {string} [resolution] -
 * @property {string} [search] -
 * @property {string} [title] -
 * @property {string} [url] -
 */
/**
 * @typedef PaymentInfoEntered
 * @property {string} [checkout_id] - Checkout transaction ID
 * @property {string} order_id - Order/transaction ID
 * @property {string} [payment_method] - String representing the payment method chosen
 * @property {string} [shipping_method] - String representing the shipping method chosen
 * @property {number} [step] - Number representing a step in the checkout process
 */
/**
 * @typedef VariantItem8
 * @property {string} color - The color of the product
 * @property {string} [flap_type] -
 * @property {string} [foil_color] - Foil Color: Tracks chosen foil options - rose, gold, silver.
 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
 * @property {string} material - Materials of the product
 * @property {string} [paper_color] -
 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
 */
/**
 * @typedef ProductAdded
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} cart_id - cart ID associated with the product displayed
 * @property {string} category - Product category being viewed
 * @property {boolean} [has_design_notes] - Whether a product customization has a design note. If yes, it does. if no, it doesn't
 * @property {string} image_url - Image url of the product
 * @property {string} name - Name of the product
 * @property {string} platform - The platform of the application where the event fired
 * @property {string} position - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} product_id - Database ID of the product
 * @property {number} quantity - Quantity of the product
 * @property {boolean} sample - Is the product a sample?
 * @property {string} sku - Sku of the product
 * @property {string} url - URL of the product page for product
 * @property {VariantItem8[]} variant - Product Variant displayed in the list
 */
/**
 * @typedef ProductAddedToWishlist
 * @property {string} [brand] - Brand of the product
 * @property {string} [category] - Product category being viewed
 * @property {string} [color] - The color of the product
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [format] -
 * @property {string} [image_url] - Image url of the product
 * @property {any[]} [material] - Materials of the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} [printing_options] - Which printing option did the customer specify?
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 * @property {string} wishlist_id - Wishlist ID to which the product was added
 * @property {string} [wishlist_name] - Wishlist name to which the product was added
 */
/**
 * @typedef ProductClicked
 * @property {string} [brand] - Brand of the product
 * @property {string} [cart_id] - cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [color] - The color of the product
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [format] -
 * @property {string} [image_url] - Image url of the product
 * @property {string} [location] - Location where the product was clicked
 * @property {any[]} [material] - Materials of the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} [printing_options] - Which printing option did the customer specify?
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef ProductClickedQuickLook
 * @property {string} [brand] - Brand of the product
 * @property {string} [cart_id] - cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [color] - The color of the product
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [format] -
 * @property {string} [image_url] - Image url of the product
 * @property {string} [location] - Location where the product was clicked
 * @property {any[]} [material] - Materials of the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} [printing_options] - Which printing option did the customer specify?
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef ProductConfigured
 * @property {string} [brand] - Brand of the product
 * @property {string} [cart_id] - cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [color] - The color of the product
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [format] -
 * @property {string} [image_url] - Image url of the product
 * @property {any[]} [material] - Materials of the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} [printing_options] - Which printing option did the customer specify?
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef FiltersItem
 * @property {string} [click] - Clicked element
 * @property {string} [type] - ID of the filter type being used
 * @property {string} [value] - ID of the selection chosen
 */
/**
 * @typedef ProductsItem7
 * @property {string} [brand] - Product Brand displayed in the list
 * @property {string} [category] - Product Category displayed in the list
 * @property {string} [color] - The color of the product
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [format] -
 * @property {string} [image_url] - Image url of the product in the list
 * @property {any[]} [material] - Materials of the product
 * @property {string} [name] - Product Name displayed in the list
 * @property {number} [position] - Position of product in the list
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} [sku] - Product SKU displayed in the list
 * @property {string} [url] - URL of the product page for product in the list
 * @property {string} [variant] - Product Variant displayed in the list
 */
/**
 * @typedef SortsItem
 * @property {string} [type] - ID of the sort type being used
 * @property {string} [value] - ID of the selection type being used
 */
/**
 * @typedef ProductListFiltered
 * @property {string} [category] - Product category being viewed
 * @property {FiltersItem[]} [filters] - Product filters that the customer is using
 * @property {string} [list_id] - Product list being viewed
 * @property {string} [location] - Location where Product List Viewed was triggered
 * @property {string} [printing_options] - Which printing option did the customer specify?
 * @property {ProductsItem7[]} products - Products displayed in the product list
 * @property {SortsItem[]} [sorts] - Product sorting the customer is using
 */
/**
 * @typedef ProductsItem8
 * @property {string} [brand] - Product Brand displayed in the list
 * @property {string} [category] - Product Category displayed in the list
 * @property {string} [color] - The color of the product
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [format] -
 * @property {string} [image_url] - Image url of the product in the list
 * @property {any[]} [material] - Materials of the product
 * @property {string} [name] - Product Name displayed in the list
 * @property {number} [position] - Position of product in the list
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} product_id - Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {string} [sku] - Product SKU displayed in the list
 * @property {string} [url] - URL of the product page for product in the list
 * @property {string} [variant] - Product Variant displayed in the list
 */
/**
 * @typedef ProductListViewed
 * @property {string} [category] - Product category being viewed
 * @property {string} [list_id] - Product list being viewed
 * @property {string} [location] - Location where Product List Viewed was triggered
 * @property {string} [printing_options] - Which printing option did the customer specify?
 * @property {ProductsItem8[]} products - Products displayed in the product list
 */
/**
 * @typedef VariantItem9
 * @property {string} color - The color of the product
 * @property {string} foil_color - Foil Color: Tracks chosen foil options - rose, gold, silver.
 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
 * @property {string} material - Materials of the product
 * @property {string} printing_options - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
 */
/**
 * @typedef ProductMoved
 * @property {string} cart_id - cart ID associated with the product displayed
 * @property {string} category - The category of the page, added to the properties object.
 * @property {string} checkout_id - Shopping cart ID - this is a replicate of the cart_id field
 * @property {boolean} has_design_notes - Whether a product customization has a design note. If yes, it does. if no, it doesn't 
 * @property {string} image_url - Image url of the product in the list
 * @property {string} name - Product Name displayed in the list
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web"
 * @property {string} portfolioItemId - portfolioItemId
 * @property {number} [position] - Position of product in the list
 * @property {number} [price] - Product Price displayed in the list
 * @property {string} product_id - Database ID of the product/Product ID displayed in the list
 * @property {number} [quantity] - Product quantity displayed in the list
 * @property {boolean} sample - Indicates whether the product selected is a sample (true/false)
 * @property {string} sku - Product SKU displayed in the list
 * @property {string} [surface] - Name of the paper surface that it's being customized 
 * @property {string} url - URL of the product page for product in the list
 * @property {string} value - Total value of the product after quantity
 * @property {VariantItem9[]} variant - Product Variant displayed in the list
 */
/**
 * @typedef VariantItem10
 * @property {string} color - The color of the product
 * @property {string} [flap_type] -
 * @property {string} foil_color - Foil Color: Tracks chosen foil options - rose, gold, silver.
 * @property {string} format - Layout and/or material of the product, such as petite card, standard, magnet, all-in-one, etc
 * @property {string} material - Materials of the product
 * @property {string} [paper_color] -
 * @property {string} [printing_options] - Printing options for an all-in-one or envelopes (if applicable) - "PLN", "BTH", "REC", "RET"
 */
/**
 * @typedef ProductRemoved
 * @property {string} affiliation - Store or affiliation from which this transaction occurred
 * @property {string} cart_id - cart ID associated with the product displayed
 * @property {string} category - The category of the page, added to the properties object.
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {boolean} has_design_notes - Whether a product customization has a design note. If yes, it does. if no, it doesn't 
 * @property {string} image_url - Image url of the product in the list
 * @property {string} [name] - Name of the product
 * @property {string} platform - "Reflects platform. Will leverage values identified by Data team:
mobile app ios
mobile app android
desktop web
mobile web"
 * @property {number} position - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} product_id - Database ID of the product
 * @property {number} quantity - Quantity of the product
 * @property {string} sku - Sku of the product
 * @property {string} url - URL of the product page for product
 * @property {VariantItem10[]} variant - Product Variant displayed in the list
 */
/**
 * @typedef ProductRemovedFromWishlist
 * @property {string} [brand] - Brand of the product
 * @property {string} [category] - Product category being viewed
 * @property {string} [color] - The color of the product
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [format] -
 * @property {string} [image_url] - Image url of the product
 * @property {any[]} [material] - Materials of the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} [printing_options] - Which printing option did the customer specify?
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 * @property {string} wishlist_id - Wishlist ID to which the product was added
 * @property {string} [wishlist_name] - Wishlist name to which the product was added
 */
/**
 * @typedef ProductReviewed
 * @property {string} [format] -
 * @property {string} [printing_options] - Which printing option did the customer specify?
 * @property {string} product_id - Database ID of the product
 * @property {number} [rating] - Star rating
 * @property {string} [review_body] - Content of the review
 * @property {string} [review_id] - ID of the review posted
 */
/**
 * @typedef ProductShared
 * @property {string} [brand] - Brand of the product
 * @property {string} [category] - Product category being viewed
 * @property {string} [color] - The color of the product
 * @property {string} [format] -
 * @property {string} [image_url] - Image url of the product
 * @property {any[]} [material] - Materials of the product
 * @property {string} [name] - Name of the product
 * @property {number} price - Price of the product
 * @property {string} [printing_options] - Which printing option did the customer specify?
 * @property {string} product_id - Database ID of the product
 * @property {string} [recipient] - Recipient of the sharing
 * @property {string} [share_message] - Message that the sender sent
 * @property {string} share_via - Method of sharing
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef ProductViewed
 * @property {string} [brand] - Brand of the product
 * @property {string} [cart_id] - cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [color] - The color of the product
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [format] -
 * @property {string} [image_url] - Image url of the product
 * @property {string} [location] - Location where the product was clicked
 * @property {any[]} [material] - Materials of the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} [printing_options] - Which printing option did the customer specify?
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 */
/**
 * @typedef ProductsSearched
 * @property {string} [platform] -
 * @property {string} [query] - Query the user searched with
 * @property {string} [section] -
 * @property {string} [selection] -
 */
/**
 * @typedef PromotionClicked
 * @property {string} [promotion_creative] - Description of promotion's creative
 * @property {string} [promotion_id] - ID of the promotion
 * @property {string} [promotion_name] - Name of the promotion
 * @property {string} [promotion_position] - Position of the promotion
 */
/**
 * @typedef PromotionViewed
 * @property {string} [promotion_creative] - Description of promotion's creative
 * @property {string} [promotion_id] - ID of the promotion
 * @property {string} [promotion_name] - Name of the promotion
 * @property {string} [promotion_position] - Position of the promotion
 */
/**
 * @typedef RegistrationFormDisplayed
 * @property {string} [flowType] - Type of flow: proactive
 * @property {string} [platform] - Custom Agent: web
 * @property {string} [product] - TK product: paper
 * @property {string} [reason] - Reason and location
 * @property {string} [selection] -
 * @property {number} [stepCount] - Step
 * @property {number} [stepNumber] - Step
 */
/**
 * @typedef VeraWangLandingPageInteraction
 * @property {string} [platform] - Custom Agent: web
 * @property {string} [product] - TK product: marketing-landing-pages
 * @property {string} [resolution] - Device resolution
 * @property {string} [selection] - Area clicked
 * @property {string} [userDecisionArea] -
 */
/**
 * @typedef WishlistProductAddedToCart
 * @property {string} [brand] - Brand of the product
 * @property {string} [cart_id] - cart ID associated with the product displayed
 * @property {string} [category] - Product category being viewed
 * @property {string} [color] - The color of the product
 * @property {string} [coupon] - Coupon code associated with the product
 * @property {string} [image_url] - Image url of the product
 * @property {any[]} [material] - Materials of the product
 * @property {string} [name] - Name of the product
 * @property {number} [position] - Position of product in the list
 * @property {number} price - Price of the product
 * @property {string} [printing_options] - Which printing option did the customer specify?
 * @property {string} product_id - Database ID of the product
 * @property {number} [quantity] - Quantity of the product
 * @property {string} [sku] - Sku of the product
 * @property {string} [url] - URL of the product page for product
 * @property {string} [variant] - Variant of the product
 * @property {string} wishlist_id - Wishlist ID to which the product was added
 * @property {string} [wishlist_name] - Wishlist name to which the product was added
 */

/**
 * Mixpanel experiment
 *
 * @param {ExperimentStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function experimentStarted(
	props: ExperimentStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'$experiment_started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'CTA Clicked' track call.
 *
 * @param {CtaClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function ctaClicked(
	props?: CtaClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'CTA Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Shared the cart with one or more friends
 *
 * @param {CartShared} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cartShared(
	props: CartShared,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cart Shared',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed their shopping cart
 *
 * @param {CartViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function cartViewed(
	props: CartViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Cart Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User initiated the order process (a transaction is created)
 *
 * @param {CheckoutStarted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStarted(
	props: CheckoutStarted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Started',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completed a checkout step
 *
 * @param {CheckoutStepCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStepCompleted(
	props: CheckoutStepCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Step Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed a checkout step
 *
 * @param {CheckoutStepViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function checkoutStepViewed(
	props?: CheckoutStepViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Checkout Step Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Coupon was applied on a user’s shopping cart or order
 *
 * @param {CouponApplied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function couponApplied(
	props: CouponApplied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Coupon Applied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Coupon was denied from a user’s shopping cart or order
 *
 * @param {CouponDenied} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function couponDenied(
	props: CouponDenied,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Coupon Denied',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User entered a coupon on a shopping cart or order
 *
 * @param {CouponEntered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function couponEntered(
	props: CouponEntered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Coupon Entered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User removed a coupon from a cart or order
 *
 * @param {CouponRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function couponRemoved(
	props: CouponRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Coupon Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User cancelled the current customization.
 *
 * @param {CustomizationCancelled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customizationCancelled(
	props: CustomizationCancelled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customization Cancelled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User started a customization.
 *
 * @param {CustomizationInitialized} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customizationInitialized(
	props: CustomizationInitialized,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customization Initialized',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User performed a customization action.
 *
 * @param {CustomizationInteraction} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customizationInteraction(
	props: CustomizationInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customization Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tracks the action of items (image, text, shape or QR Code) is removed within the design editor. It maps to the event fired by Customer Canvas Platform
 *
 * @param {CustomizationItemAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customizationItemAdded(
	props: CustomizationItemAdded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customization Item Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tracks the action of items (image, text, shape or QR Code) is removed within the design editor. It maps to the event fired by Customer Canvas Platform
 *
 * @param {CustomizationItemChanged} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customizationItemChanged(
	props: CustomizationItemChanged,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customization Item Changed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Tracks the action of items (image, text, shape or QR Code) is added within the design editor. It maps to the event fired by Customer Canvas Platform.
 *
 * @param {CustomizationItemRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customizationItemRemoved(
	props: CustomizationItemRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customization Item Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User saved and completed the current customization.
 *
 * @param {CustomizationReviewInitialized} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customizationReviewInitialized(
	props: CustomizationReviewInitialized,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customization Review Initialized',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User saved and completed the current customization.
 *
 * @param {CustomizationSavedForReview} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function customizationSavedForReview(
	props: CustomizationSavedForReview,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Customization Saved For Review',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Draft Created' track call.
 *
 * @param {DraftCreated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function draftCreated(
	props?: DraftCreated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Draft Created',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Draft Deleted' track call.
 *
 * @param {DraftDeleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function draftDeleted(
	props: DraftDeleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Draft Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Draft List Viewed' track call.
 *
 * @param {DraftListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function draftListViewed(
	props: DraftListViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Draft List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Draft Updated' track call.
 *
 * @param {DraftUpdated} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function draftUpdated(
	props?: DraftUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Draft Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Footer Interaction' track call.
 *
 * @param {FooterInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function footerInteraction(
	props?: FooterInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Footer Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Information Entered' track call.
 *
 * @param {InformationEntered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function informationEntered(
	props: InformationEntered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Information Entered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Log In' track call.
 *
 * @param {LogIn} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function logIn(
	props?: LogIn,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track('Log In', props || {}, withTypewriterContext(options), callback)
	}
}
/**
 * Fires a 'Log In Form Displayed' track call.
 *
 * @param {LogInFormDisplayed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function logInFormDisplayed(
	props?: LogInFormDisplayed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Log In Form Displayed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Menu Interaction' track call.
 *
 * @param {MenuInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function menuInteraction(
	props?: MenuInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Menu Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Message Shown' track call.
 *
 * @param {MessageShown} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function messageShown(
	props?: MessageShown,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Message Shown',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'New Registration' track call.
 *
 * @param {NewRegistration} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function newRegistration(
	props?: NewRegistration,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'New Registration',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User cancelled the order
 *
 * @param {OrderCancelled} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCancelled(
	props: OrderCancelled,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Order Cancelled',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User completed the order
 *
 * @param {OrderCompleted} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderCompleted(
	props: OrderCompleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Order Completed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User refunded the order
 *
 * @param {OrderRefunded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderRefunded(
	props: OrderRefunded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Order Refunded',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User updated the order
 *
 * @param {OrderUpdated} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function orderUpdated(
	props: OrderUpdated,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Order Updated',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Page Viewed' track call.
 *
 * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function pageViewed(
	props?: PageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User added payment information
 *
 * @param {PaymentInfoEntered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function paymentInfoEntered(
	props: PaymentInfoEntered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Payment Info Entered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User added a product to their cart
 *
 * @param {ProductAdded} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productAdded(
	props: ProductAdded,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Added',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User added a product to the wish list
 *
 * @param {ProductAddedToWishlist} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productAddedToWishlist(
	props: ProductAddedToWishlist,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Added to Wishlist',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicked through to a product page
 *
 * @param {ProductClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productClicked(
	props: ProductClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicked a product
 *
 * @param {ProductClickedQuickLook} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productClickedQuickLook(
	props: ProductClickedQuickLook,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Clicked - Quick Look',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User updated configuration of product on product detail page
 *
 * @param {ProductConfigured} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productConfigured(
	props: ProductConfigured,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Configured',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User filtered a product list or category
 *
 * @param {ProductListFiltered} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productListFiltered(
	props: ProductListFiltered,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product List Filtered',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed a product list or category
 *
 * @param {ProductListViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productListViewed(
	props: ProductListViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product List Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Product Moved' track call.
 *
 * @param {ProductMoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productMoved(
	props: ProductMoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Moved',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User removed a product from their shopping cart
 *
 * @param {ProductRemoved} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productRemoved(
	props: ProductRemoved,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Removed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User removed a product from the wish list
 *
 * @param {ProductRemovedFromWishlist} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productRemovedFromWishlist(
	props: ProductRemovedFromWishlist,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Removed from Wishlist',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User reviewed a product
 *
 * @param {ProductReviewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productReviewed(
	props: ProductReviewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Reviewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Shared a product with one or more friends
 *
 * @param {ProductShared} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productShared(
	props: ProductShared,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Shared',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed a product details
 *
 * @param {ProductViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productViewed(
	props: ProductViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Product Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User searched for products
 *
 * @param {ProductsSearched} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function productsSearched(
	props?: ProductsSearched,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Products Searched',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User clicked on promotion
 *
 * @param {PromotionClicked} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function promotionClicked(
	props: PromotionClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Promotion Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User viewed promotion
 *
 * @param {PromotionViewed} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function promotionViewed(
	props: PromotionViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Promotion Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Registration Form Displayed' track call.
 *
 * @param {RegistrationFormDisplayed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function registrationFormDisplayed(
	props?: RegistrationFormDisplayed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Registration Form Displayed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Fires a 'Vera Wang Landing Page Interaction' track call.
 *
 * @param {VeraWangLandingPageInteraction} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function veraWangLandingPageInteraction(
	props?: VeraWangLandingPageInteraction,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Vera Wang Landing Page Interaction',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * User added a wishlist product to the cart
 *
 * @param {WishlistProductAddedToCart} props - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function wishlistProductAddedToCart(
	props: WishlistProductAddedToCart,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Wishlist Product Added to Cart',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * Mixpanel experiment
	 *
	 * @param {ExperimentStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	experimentStarted,
	/**
	 * Fires a 'CTA Clicked' track call.
	 *
	 * @param {CtaClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	ctaClicked,
	/**
	 * Shared the cart with one or more friends
	 *
	 * @param {CartShared} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cartShared,
	/**
	 * User viewed their shopping cart
	 *
	 * @param {CartViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	cartViewed,
	/**
	 * User initiated the order process (a transaction is created)
	 *
	 * @param {CheckoutStarted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStarted,
	/**
	 * User completed a checkout step
	 *
	 * @param {CheckoutStepCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStepCompleted,
	/**
	 * User viewed a checkout step
	 *
	 * @param {CheckoutStepViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	checkoutStepViewed,
	/**
	 * Coupon was applied on a user’s shopping cart or order
	 *
	 * @param {CouponApplied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	couponApplied,
	/**
	 * Coupon was denied from a user’s shopping cart or order
	 *
	 * @param {CouponDenied} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	couponDenied,
	/**
	 * User entered a coupon on a shopping cart or order
	 *
	 * @param {CouponEntered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	couponEntered,
	/**
	 * User removed a coupon from a cart or order
	 *
	 * @param {CouponRemoved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	couponRemoved,
	/**
	 * User cancelled the current customization.
	 *
	 * @param {CustomizationCancelled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customizationCancelled,
	/**
	 * User started a customization.
	 *
	 * @param {CustomizationInitialized} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customizationInitialized,
	/**
	 * User performed a customization action.
	 *
	 * @param {CustomizationInteraction} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customizationInteraction,
	/**
	 * Tracks the action of items (image, text, shape or QR Code) is removed within the design editor. It maps to the event fired by Customer Canvas Platform
	 *
	 * @param {CustomizationItemAdded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customizationItemAdded,
	/**
	 * Tracks the action of items (image, text, shape or QR Code) is removed within the design editor. It maps to the event fired by Customer Canvas Platform
	 *
	 * @param {CustomizationItemChanged} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customizationItemChanged,
	/**
	 * Tracks the action of items (image, text, shape or QR Code) is added within the design editor. It maps to the event fired by Customer Canvas Platform.
	 *
	 * @param {CustomizationItemRemoved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customizationItemRemoved,
	/**
	 * User saved and completed the current customization.
	 *
	 * @param {CustomizationReviewInitialized} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customizationReviewInitialized,
	/**
	 * User saved and completed the current customization.
	 *
	 * @param {CustomizationSavedForReview} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	customizationSavedForReview,
	/**
	 * Fires a 'Draft Created' track call.
	 *
	 * @param {DraftCreated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	draftCreated,
	/**
	 * Fires a 'Draft Deleted' track call.
	 *
	 * @param {DraftDeleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	draftDeleted,
	/**
	 * Fires a 'Draft List Viewed' track call.
	 *
	 * @param {DraftListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	draftListViewed,
	/**
	 * Fires a 'Draft Updated' track call.
	 *
	 * @param {DraftUpdated} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	draftUpdated,
	/**
	 * Fires a 'Footer Interaction' track call.
	 *
	 * @param {FooterInteraction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	footerInteraction,
	/**
	 * Fires a 'Information Entered' track call.
	 *
	 * @param {InformationEntered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	informationEntered,
	/**
	 * Fires a 'Log In' track call.
	 *
	 * @param {LogIn} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	logIn,
	/**
	 * Fires a 'Log In Form Displayed' track call.
	 *
	 * @param {LogInFormDisplayed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	logInFormDisplayed,
	/**
	 * Fires a 'Menu Interaction' track call.
	 *
	 * @param {MenuInteraction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	menuInteraction,
	/**
	 * Fires a 'Message Shown' track call.
	 *
	 * @param {MessageShown} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	messageShown,
	/**
	 * Fires a 'New Registration' track call.
	 *
	 * @param {NewRegistration} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	newRegistration,
	/**
	 * User cancelled the order
	 *
	 * @param {OrderCancelled} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderCancelled,
	/**
	 * User completed the order
	 *
	 * @param {OrderCompleted} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderCompleted,
	/**
	 * User refunded the order
	 *
	 * @param {OrderRefunded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderRefunded,
	/**
	 * User updated the order
	 *
	 * @param {OrderUpdated} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	orderUpdated,
	/**
	 * Fires a 'Page Viewed' track call.
	 *
	 * @param {PageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	pageViewed,
	/**
	 * User added payment information
	 *
	 * @param {PaymentInfoEntered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	paymentInfoEntered,
	/**
	 * User added a product to their cart
	 *
	 * @param {ProductAdded} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productAdded,
	/**
	 * User added a product to the wish list
	 *
	 * @param {ProductAddedToWishlist} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productAddedToWishlist,
	/**
	 * User clicked through to a product page
	 *
	 * @param {ProductClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productClicked,
	/**
	 * User clicked a product
	 *
	 * @param {ProductClickedQuickLook} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productClickedQuickLook,
	/**
	 * User updated configuration of product on product detail page
	 *
	 * @param {ProductConfigured} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productConfigured,
	/**
	 * User filtered a product list or category
	 *
	 * @param {ProductListFiltered} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productListFiltered,
	/**
	 * User viewed a product list or category
	 *
	 * @param {ProductListViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productListViewed,
	/**
	 * Fires a 'Product Moved' track call.
	 *
	 * @param {ProductMoved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productMoved,
	/**
	 * User removed a product from their shopping cart
	 *
	 * @param {ProductRemoved} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productRemoved,
	/**
	 * User removed a product from the wish list
	 *
	 * @param {ProductRemovedFromWishlist} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productRemovedFromWishlist,
	/**
	 * User reviewed a product
	 *
	 * @param {ProductReviewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productReviewed,
	/**
	 * Shared a product with one or more friends
	 *
	 * @param {ProductShared} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productShared,
	/**
	 * User viewed a product details
	 *
	 * @param {ProductViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productViewed,
	/**
	 * User searched for products
	 *
	 * @param {ProductsSearched} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	productsSearched,
	/**
	 * User clicked on promotion
	 *
	 * @param {PromotionClicked} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	promotionClicked,
	/**
	 * User viewed promotion
	 *
	 * @param {PromotionViewed} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	promotionViewed,
	/**
	 * Fires a 'Registration Form Displayed' track call.
	 *
	 * @param {RegistrationFormDisplayed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	registrationFormDisplayed,
	/**
	 * Fires a 'Vera Wang Landing Page Interaction' track call.
	 *
	 * @param {VeraWangLandingPageInteraction} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	veraWangLandingPageInteraction,
	/**
	 * User added a wishlist product to the cart
	 *
	 * @param {WishlistProductAddedToCart} props - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	wishlistProductAddedToCart,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/xogrp/protocols/tracking-plans/rs_1arPWFY0COkNwlWvMNcGms7HFeI`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
