// TK
import { Category, HrefToCategoryMap, SkuToCategoryMap } from './types'

const saveTheDatesLink = '/save-the-dates'
const changeTheDatesLink = '/change-the-dates'
const invitationsLink = '/invitations'
const ceremonyReceptionLink = '/ceremony-reception'
const thankYousLink = '/thank-yous'
const holidayCardsLink = '/holiday-cards'
const envelopesLink = '/envelopes'

const saveTheDateCards: Category = {
  sku: 'SDC',
  name: 'Save the Date Cards',
  nameTextOverride: 'Standard Cards',
  seoTags: {
    title: 'Wedding Save The Dates | The Knot',
    description:
      'Share the excitement by sending guests customizable save-the-date cards. Choose from hundreds of gorgeous designs, from elegant to rustic.',
    abstract:
      'Share the excitement by sending guests customizable save-the-date cards. Choose from hundreds of gorgeous designs, from elegant to rustic.',
    robots: null,
  },
  href: `${saveTheDatesLink}?format=standard`,
  alternateHref: `${saveTheDatesLink}/save-the-date-cards`,
  parentSku: 'STDA',
  formats: ['postcard', 'petite', 'standard', 'magnet'],
}
const saveTheDatePostCards: Category = {
  sku: 'SDP',
  name: 'Save the Date Postcards',
  nameTextOverride: 'Postcards',
  seoTags: {
    title: 'Wedding Save The Date Postcards | The Knot',
    description:
      'Help guests mark their calendars by sending customizable save-the-date postcards. Choose from hundreds of gorgeous designs, from elegant to rustic.',
    abstract:
      'Help guests mark their calendars by sending customizable save-the-date postcards. Choose from hundreds of gorgeous designs, from elegant to rustic.',
    robots: null,
  },
  href: `${saveTheDatesLink}?format=postcard`,
  alternateHref: `${saveTheDatesLink}/save-the-date-postcards`,
  parentSku: 'STDA',
  formats: ['postcard', 'petite', 'standard', 'magnet'],
}

const saveTheDatePetiteCards: Category = {
  sku: 'SDT',
  name: 'Save the Date Petite Cards',
  nameTextOverride: 'Petite Cards',
  seoTags: {
    title: 'Petite Wedding Save the Date Cards | The Knot',
    description:
      'Petite save the date cards make it easy to stay in budget. Choose from customizable designs ranging from elegant to rustic to match your wedding vision.',
    abstract:
      'Petite save the date cards make it easy to stay in budget. Choose from customizable designs ranging from elegant to rustic to match your wedding vision.',
    robots: null,
  },
  href: `${saveTheDatesLink}?format=petite`,
  parentSku: 'STDA',
  formats: ['postcard', 'petite', 'standard', 'magnet'],
}

const savetheDateMagnets: Category = {
  sku: 'SDM',
  name: 'Save the Date Magnets',
  nameTextOverride: 'Magnets',
  seoTags: {
    title: 'Wedding Save The Date Magnets | The Knot',
    description: `Announce your wedding with customizable save the date magnets. These magnetic cards stick to your guests' refrigerators for a regular reminder of your big day.`,
    abstract: `Announce your wedding with customizable save the date magnets. These magnetic cards stick to your guests' refrigerators for a regular reminder of your big day.`,
    robots: null,
  },
  href: `${saveTheDatesLink}?format=magnet`,
  alternateHref: `${saveTheDatesLink}/save-the-date-magnets`,
  parentSku: 'STDA',
  formats: ['postcard', 'petite', 'standard', 'magnet'],
}

const changeTheDateCards: Category = {
  sku: 'CDC',
  name: 'Change the Date Cards',
  nameTextOverride: 'Change the Date Cards',
  seoTags: {
    title: 'Wedding Change The Date Cards | The Knot',
    description:
      'Change of plans? Keep guests in the loop with customizable change-the-date cards. Choose from hundreds of affordable designs.',
    abstract:
      'Change of plans? Keep guests in the loop with customizable change-the-date cards. Choose from hundreds of affordable designs.',
    robots: null,
  },
  href: `${changeTheDatesLink}/change-the-date-cards`,
  parentSku: 'CTDA',
}
const changeTheDatePostCards: Category = {
  sku: 'CDP',
  name: 'Change the Date Postcards',
  nameTextOverride: 'Change the Date Postcards',
  seoTags: {
    title: 'Wedding Change The Date Postcards | The Knot',
    description:
      'Share your updated wedding plans in style with change-the-date postcards. Choose your favorite design then customize it to match your style.',
    abstract:
      'Share your updated wedding plans in style with change-the-date postcards. Choose your favorite design then customize it to match your style.',
    robots: null,
  },
  href: `${changeTheDatesLink}/change-the-date-postcards`,
  parentSku: 'CTDA',
}

export const saveTheDates: Category = {
  sku: 'STDA',
  name: 'Save the Dates',
  seoTags: {
    title: 'Wedding Save The Dates | Elegant to Rustic Designs | The Knot',
    description:
      'Announce your wedding with customizable save-the-date cards. With hundreds of styles, from elegant to rustic, you can find one that matches your wedding vision.',
    abstract:
      'Announce your wedding with customizable save-the-date cards. With hundreds of styles, from elegant to rustic, you can find one that matches your wedding vision.',
    robots: null,
  },
  href: saveTheDatesLink,
  subCategories: [saveTheDateCards, saveTheDatePostCards, saveTheDatePetiteCards, savetheDateMagnets],
  formats: ['postcard', 'petite', 'standard', 'magnet'],
}

export const petitesMagnets: Category = {
  sku: 'STDA',
  name: 'Save the Dates',
  seoTags: {
    title: 'Wedding Save The Dates | Elegant to Rustic Designs | The Knot',
    description:
      'Announce your wedding with customizable save-the-date cards. With hundreds of styles, from elegant to rustic, you can find one that matches your wedding vision.',
    abstract:
      'Announce your wedding with customizable save-the-date cards. With hundreds of styles, from elegant to rustic, you can find one that matches your wedding vision.',
    robots: null,
  },
  href: saveTheDatesLink,
}

export const changeTheDates: Category = {
  sku: 'CTDA',
  name: 'Change the Dates',
  seoTags: {
    title: 'Change The Dates | Wedding Postponement Stationery | The Knot',
    description:
      'Change of plans? Update your guests about your wedding postponement in style with affordable, customizable wedding change-the-date cards.',
    abstract:
      'Change of plans? Update your guests about your wedding postponement in style with affordable, customizable wedding change-the-date cards.',
    robots: null,
  },
  href: changeTheDatesLink,
  subCategories: [changeTheDateCards, changeTheDatePostCards],
  formats: ['postcard', 'standard'],
}

export const weddingInvitations: Category = {
  sku: 'WIN',
  name: 'Wedding Invitations',
  seoTags: {
    title: 'Wedding Invitations | The Knot',
    description: 'Looking for custom wedding invitations? Discover hundreds of affordable designs made just for you.',
    abstract: 'Looking for custom wedding invitations? Discover hundreds of affordable designs made just for you.',
    robots: null,
  },
  href: `${invitationsLink}/wedding`,
  parentSku: 'INVI',
  formats: ['standard', 'all-in-one'],
}
const rsvpCards: Category = {
  sku: 'RSC',
  name: 'RSVP Cards',
  seoTags: {
    title: 'Wedding RSVP Cards | The Knot',
    description:
      'Find affordable wedding RSVP cards that your guests will love. First choose your design, then customize it to match your wedding vision.',
    abstract:
      'Find affordable wedding RSVP cards that your guests will love. First choose your design, then customize it to match your wedding vision.',
    robots: null,
  },
  href: `/rsvp-cards`,
  parentSku: 'INVI',
}
const enclosureCards: Category = {
  sku: 'ENC',
  name: 'Enclosure Cards',
  seoTags: {
    title: 'Wedding Enclosure Cards | The Knot',
    description:
      'Give guests the need-to-know details with a stylish wedding enclosure card. Customize and match it to your wedding stationery suite.',
    abstract:
      'Give guests the need-to-know details with a stylish wedding enclosure card. Customize and match it to your wedding stationery suite.',
    robots: null,
  },
  href: `/enclosure-cards`,
  parentSku: 'INVI',
}
const envelopeLiners: Category = {
  sku: 'EVL',
  name: 'Envelope Liners',
  seoTags: {
    title: 'Envelope Liners | The Knot',
    description:
      'Surprise guests by selecting envelope liners as a fun finishing touch. Choose your favorite design and customize it to complement your wedding stationery suite.',
    abstract:
      'Surprise guests by selecting envelope liners as a fun finishing touch. Choose your favorite design and customize it to complement your wedding stationery suite.',
    robots: null,
  },
  href: `/envelope-liners`,
  parentSku: 'INVI',
}
const bridalShowerInvitations: Category = {
  sku: 'BRI',
  name: 'Bridal Shower Invitations',
  seoTags: {
    title: 'Bridal Shower Invitations | The Knot',
    description:
      'Invite guests to your bridal shower or wedding shower with customizable bridal shower invitations. Choose from hundreds of designs to match your wedding vision.',
    abstract:
      'Invite guests to your bridal shower or wedding shower with customizable bridal shower invitations. Choose from hundreds of designs to match your wedding vision.',
    robots: null,
  },
  href: `${invitationsLink}/bridal-shower`,
  parentSku: 'INVI',
}
const rehearsalDinnerInvitations: Category = {
  sku: 'RDI',
  name: 'Rehearsal Dinner Invitations',
  seoTags: {
    title: 'Rehearsal Dinner Invitations | The Knot',
    description:
      'Invite guests to kick off your wedding with customizable rehearsal dinner invitations. Choose from hundreds of designs to match your wedding vision.',
    abstract:
      'Invite guests to kick off your wedding with customizable rehearsal dinner invitations. Choose from hundreds of designs to match your wedding vision.',
    robots: null,
  },
  href: `${invitationsLink}/rehearsal-dinner`,
  parentSku: 'INVI',
}
export const allInOneWeddingInvitations: Category = {
  sku: 'AIO' /* ! not defined in BC */,
  name: 'All-in-One Wedding Invitations',
  seoTags: {
    title: 'All-in-One Wedding Invitations | Seal and Send | The Knot',
    description:
      'All-in-one invitations make it easy to stay in budget. Get your wedding invitation, RSVP card and envelope in one. All you have to do is seal and send to your guests.',
    abstract:
      'All-in-one invitations make it easy to stay in budget. Get your wedding invitation, RSVP card and envelope in one. All you have to do is seal and send to your guests.',
    robots: null,
  },
  href: `${invitationsLink}/wedding?format=all-in-one`,
  parentSku: 'INVI',
  breadcrumbAs: weddingInvitations,
  formats: ['all-in-one', 'standard'],
}

export const invitations: Category = {
  sku: 'INVI',
  name: 'Invitations',
  seoTags: {
    title: 'Wedding Invitations | Elegant to Rustic Designs | The Knot',
    description:
      'Explore hundreds of affordable wedding invitations that are just your style. From elegant to rustic, customize an invitation designed for your love story.',
    abstract:
      'Explore hundreds of affordable wedding invitations that are just your style. From elegant to rustic, customize an invitation designed for your love story.',
    robots: null,
  },
  href: invitationsLink,
  subCategories: [
    weddingInvitations,
    enclosureCards,
    rsvpCards,
    envelopeLiners,
    bridalShowerInvitations,
    rehearsalDinnerInvitations,
  ],
  formats: ['all-in-one', 'standard'],
}
export const allInOneInvitations: Category = {
  sku: 'AIOINV',
  name: 'All-in-One Wedding Invitations',
  seoTags: {
    title: 'All-in-One Invitations with RSVP Cards | The Knot',
    description:
      'Budget-friendly and easily customizable. All-in-one wedding invitations include your wedding invitation, RSVP card and envelope. Free guest and return addressing.',
    abstract:
      'Budget-friendly and easily customizable. All-in-one wedding invitations include your wedding invitation, RSVP card and envelope. Free guest and return addressing.',
    robots: null,
  },
  href: `${invitationsLink}?format=all-in-one`,
  breadcrumbAs: weddingInvitations,
  formats: ['all-in-one', 'standard'],
}

const programs: Category = {
  sku: 'PRG',
  name: 'Programs',
  seoTags: {
    title: 'Wedding Programs | The Knot',
    description:
      'Keep guests in-the-know by adding wedding programs to your wedding stationery suite. Choose your favorite design, then customize it to highlight your day-of details.',
    abstract:
      'Keep guests in-the-know by adding wedding programs to your wedding stationery suite. Choose your favorite design, then customize it to highlight your day-of details.',
    robots: null,
  },
  href: `${ceremonyReceptionLink}/programs`,
  parentSku: 'CERE',
}
const placeCards: Category = {
  sku: 'PLC',
  name: 'Place Cards',
  seoTags: {
    title: 'Wedding Place and Escort Cards | The Knot',
    description:
      'Let guests know which table is theirs with wedding place and escort cards. Choose from a variety of designs to match your wedding theme, colors and style.',
    abstract:
      'Let guests know which table is theirs with wedding place and escort cards. Choose from a variety of designs to match your wedding theme, colors and style.',
    robots: null,
  },
  href: `${ceremonyReceptionLink}/place-cards`,
  parentSku: 'CERE',
}
const menus: Category = {
  sku: 'MEN',
  name: 'Menus',
  seoTags: {
    title: 'Wedding Menus | The Knot',
    description:
      "Give guests a taste of what's to come with a chic wedding menu. Choose your favorite design and customize it to match your wedding theme, colors and style.",
    abstract:
      "Give guests a taste of what's to come with a chic wedding menu. Choose your favorite design and customize it to match your wedding theme, colors and style.",
    robots: null,
  },
  href: `${ceremonyReceptionLink}/menus`,
  parentSku: 'CERE',
}
const tableNumbers: Category = {
  sku: 'TNB',
  name: 'Table Numbers',
  seoTags: {
    title: 'Wedding Table Numbers | The Knot',
    description:
      'Help guests navigate your wedding reception with wedding table numbers. Choose from a variety of designs to match your wedding theme, colors and style.',
    abstract:
      'Help guests navigate your wedding reception with wedding table numbers. Choose from a variety of designs to match your wedding theme, colors and style.',
    robots: null,
  },
  href: `${ceremonyReceptionLink}/table-numbers`,
  parentSku: 'CERE',
}
const stickers: Category = {
  sku: 'STK',
  name: 'Stickers',
  seoTags: {
    title: 'Wedding Stickers | The Knot',
    description:
      'Affordable and fun, wedding stickers make great wedding favors or envelope additions. Customize a design that showcases your style.',
    abstract:
      'Affordable and fun, wedding stickers make great wedding favors or envelope additions. Customize a design that showcases your style.',
    robots: null,
  },
  href: `${ceremonyReceptionLink}/stickers`,
  parentSku: 'CERE',
}
const giftTags: Category = {
  sku: 'GFT',
  name: 'Gift Tags',
  seoTags: {
    title: 'Wedding Gift Tags | The Knot',
    description:
      'Thank guests in a fun and memorable way with customizable wedding gift tags. Perfect for a variety of wedding favors.',
    abstract:
      'Thank guests in a fun and memorable way with customizable wedding gift tags. Perfect for a variety of wedding favors.',
    robots: null,
  },
  href: `${ceremonyReceptionLink}/gift-tags`,
  parentSku: 'CERE',
}
export const guestBooks: Category = {
  sku: 'GBK',
  name: 'Guest Books',
  seoTags: {
    title: 'Wedding Guest Books | The Knot',
    description:
      "Design the perfect keepsake—a wedding guest book. Capture guests' memories and well-wishes in a custom, one-of-a-kind book.",
    abstract:
      "Design the perfect keepsake—a wedding guest book. Capture guests' memories and well-wishes in a custom, one-of-a-kind book.",
    robots: null,
  },
  href: `${ceremonyReceptionLink}/guest-books`,
  parentSku: 'CERE',
}

export const ceremonyAndReception: Category = {
  sku: 'CERE',
  name: 'Ceremony & Reception',
  seoTags: {
    title: 'Ceremony, Reception and Wedding Day Stationery | The Knot',
    description:
      'Wow guests with customizable ceremony and reception stationery. Find memorable touches for your wedding day including programs, menus, guest books and more.',
    abstract:
      'Wow guests with customizable ceremony and reception stationery. Find memorable touches for your wedding day including programs, menus, guest books and more.',
    robots: null,
  },
  href: ceremonyReceptionLink,
  subCategories: [programs, placeCards, guestBooks, menus, stickers, tableNumbers, giftTags],
}

const thankyouCards: Category = {
  sku: 'TYC',
  name: 'Thank You Cards',
  seoTags: {
    title: 'Wedding Thank You Cards | The Knot',
    description:
      'Let guests know how thankful you are with lovely wedding thank-you cards. Completely customizable, showcase photos from your day or match it to your wedding theme.',
    abstract:
      'Let guests know how thankful you are with lovely wedding thank-you cards. Completely customizable, showcase photos from your day or match it to your wedding theme.',
    robots: null,
  },
  href: `${thankYousLink}/thank-you-cards`,
  parentSku: 'TKYS',
}
const thankyouPostCards: Category = {
  sku: 'TYP',
  name: 'Thank You Postcards',
  seoTags: {
    title: 'Wedding Thank You Postcards | The Knot',
    description:
      'Show guests your gratitude with beautiful wedding thank-you postcards. Available in a variety of designs, from elegant to rustic.',
    abstract:
      'Show guests your gratitude with beautiful wedding thank-you postcards. Available in a variety of designs, from elegant to rustic.',
    robots: null,
  },
  href: `${thankYousLink}/thank-you-postcards`,
  parentSku: 'TKYS',
}

export const holidayCardEnvelopes = {
  sku: 'HOLENV',
}

const holidayCardsBase: Category = {
  sku: 'HOL',
  name: 'Holiday Cards',
  seoTags: {
    title: 'Holiday Cards | The Knot',
    description:
      "Send your season's greetings with The Knot's holiday cards. Available in a variety of designs and customizable to match your style.",
    abstract:
      "Send your season's greetings with The Knot's holiday cards. Available in a variety of designs and customizable to match your style.",
    robots: null,
  },
  href: holidayCardsLink,
}

const holidayCardsSubCategory: Category = {
  ...holidayCardsBase,
  name: 'New! Holiday Cards',
}

export const holidayCards: Category = {
  ...holidayCardsBase,
  nameTextOverride: 'Holiday',
  subCategories: [holidayCardsSubCategory],
}

export const thankyou: Category = {
  sku: 'TKYS',
  name: 'Thank Yous',
  seoTags: {
    title: 'Wedding Thank You Cards | Elegant to Rustic Designs | The Knot',
    description:
      'Show guests your love and appreciation with customizable wedding thank-you cards. Available in a variety of designs, from elegant to rustic.',
    abstract:
      'Show guests your love and appreciation with customizable wedding thank-you cards. Available in a variety of designs, from elegant to rustic.',
    robots: null,
  },
  href: thankYousLink,
  subCategories: [thankyouCards, thankyouPostCards],
  formats: ['standard', 'postcard'],
}

const responseCardEnvelopes: Category = {
  sku: 'RSCENV',
  name: 'Response Card Envelopes',
  href: '',
  parentSku: 'ENVE',
}

const weddingInvitationEnvelopes: Category = {
  sku: 'WINENV',
  name: 'Wedding Invitation Envelopes',
  href: '',
  parentSku: 'ENVE',
}

const saveTheDateCardEnvelopes: Category = {
  sku: 'SDCENV',
  name: 'Save the Date Card Envelopes',
  href: '',
  parentSku: 'ENVE',
}

const saveTheDateMagnetdEnvelopes: Category = {
  sku: 'SDMENV',
  name: 'Save the Date Magnet Envelopes',
  href: '',
  parentSku: 'ENVE',
}

const saveTheDatePetitedEnvelopes: Category = {
  sku: 'SDTENV',
  name: 'Save the Date Petite Envelopes',
  href: '',
  parentSku: 'ENVE',
}

const bridalShowerInvitationEnvelopes: Category = {
  sku: 'BRIENV',
  name: 'Bridal Shower Invitation Envelopes',
  href: '',
  parentSku: 'ENVE',
}

const rehearsalDinnerInvitationEnvelopes: Category = {
  sku: 'RDIENV',
  name: 'Rehearsal Dinner Invitation Envelopes',
  href: '',
  parentSku: 'ENVE',
}

const thankYouCardEnvelopes: Category = {
  sku: 'TYCENV',
  name: 'Thank You Card Envelopes',
  href: '',
  parentSku: 'ENVE',
}

const changeTheDateCardEnvelopes: Category = {
  sku: 'CDCENV',
  name: 'Change The Date Card Envelopes',
  href: '',
  parentSku: 'ENVE',
}

const envelopes: Category = {
  sku: 'ENVE',
  name: 'Envelopes',
  seoTags: {
    title: 'Wedding Invitation and RSVP Envelopes | The Knot',
    description:
      'Browse our collection of envelopes to put the perfect finishing touch on your wedding invitations and stationery.',
    abstract:
      'Browse our collection of envelopes to put the perfect finishing touch on your wedding invitations and stationery.',
    robots: null,
  },
  href: envelopesLink,
  subCategories: [
    responseCardEnvelopes,
    weddingInvitationEnvelopes,
    saveTheDateCardEnvelopes,
    saveTheDateMagnetdEnvelopes,
    saveTheDatePetitedEnvelopes,
    bridalShowerInvitationEnvelopes,
    rehearsalDinnerInvitationEnvelopes,
    thankYouCardEnvelopes,
    changeTheDateCardEnvelopes,
  ],
}

export const categoriesSkuToCategoryMap: SkuToCategoryMap = {
  [thankyou.sku]: thankyou,
  [thankyouPostCards.sku]: thankyouPostCards,
  [thankyouCards.sku]: thankyouCards,
  [allInOneInvitations.sku]: allInOneInvitations,
  [allInOneWeddingInvitations.sku]: allInOneWeddingInvitations,
  [ceremonyAndReception.sku]: ceremonyAndReception,
  [guestBooks.sku]: guestBooks,
  [giftTags.sku]: giftTags,
  [stickers.sku]: stickers,
  [tableNumbers.sku]: tableNumbers,
  [menus.sku]: menus,
  [placeCards.sku]: placeCards,
  [programs.sku]: programs,
  [invitations.sku]: invitations,
  [weddingInvitations.sku]: weddingInvitations,
  [rsvpCards.sku]: rsvpCards,
  [enclosureCards.sku]: enclosureCards,
  [envelopeLiners.sku]: envelopeLiners,
  [bridalShowerInvitations.sku]: bridalShowerInvitations,
  [rehearsalDinnerInvitations.sku]: rehearsalDinnerInvitations,
  [changeTheDates.sku]: changeTheDates,
  [changeTheDateCards.sku]: changeTheDateCards,
  [changeTheDatePostCards.sku]: changeTheDatePostCards,
  [saveTheDates.sku]: saveTheDates,
  [saveTheDateCards.sku]: saveTheDateCards,
  [saveTheDatePostCards.sku]: saveTheDatePostCards,
  [saveTheDatePetiteCards.sku]: saveTheDatePetiteCards,
  [savetheDateMagnets.sku]: savetheDateMagnets,
  [holidayCards.sku]: holidayCards,
  [envelopes.sku]: envelopes,
  [responseCardEnvelopes.sku]: responseCardEnvelopes,
  [weddingInvitationEnvelopes.sku]: weddingInvitationEnvelopes,
  [saveTheDateCardEnvelopes.sku]: saveTheDateCardEnvelopes,
  [bridalShowerInvitationEnvelopes.sku]: bridalShowerInvitationEnvelopes,
  [rehearsalDinnerInvitationEnvelopes.sku]: rehearsalDinnerInvitationEnvelopes,
  [thankYouCardEnvelopes.sku]: thankYouCardEnvelopes,
  [changeTheDateCardEnvelopes.sku]: changeTheDateCardEnvelopes,
}

export const getInvitationCategoriesIds = () => {
  const ids = invitations.subCategories
    ? invitations.subCategories?.map((subcategory: Category) => subcategory.sku)
    : []
  ids.push(invitations.sku)
  return ids
}

export const getReprintCategoryIds = () => {
  const invitationIds = getInvitationCategoriesIds()
  const allowedIds = [
    changeTheDateCards.sku,
    changeTheDatePostCards.sku,
    enclosureCards.sku,
    giftTags.sku,
    guestBooks.sku,
    menus.sku,
    placeCards.sku,
    programs.sku,
    rehearsalDinnerInvitations.sku,
    rsvpCards.sku,
    saveTheDateCards.sku,
    holidayCards.sku,
    saveTheDatePostCards.sku,
    savetheDateMagnets.sku,
    saveTheDatePetiteCards.sku,
    stickers.sku,
    tableNumbers.sku,
    weddingInvitations.sku,
  ]
  return [...invitationIds, ...allowedIds]
}

export const categoriesHrefToCategoryMap: HrefToCategoryMap = {
  [thankyou.href]: thankyou,
  [thankyouPostCards.href]: thankyouPostCards,
  [thankyouCards.href]: thankyouCards,
  [allInOneInvitations.href]: allInOneInvitations,
  [allInOneWeddingInvitations.href]: allInOneWeddingInvitations,
  [ceremonyAndReception.href]: ceremonyAndReception,
  [guestBooks.href]: guestBooks,
  [giftTags.href]: giftTags,
  [stickers.href]: stickers,
  [tableNumbers.href]: tableNumbers,
  [menus.href]: menus,
  [placeCards.href]: placeCards,
  [programs.href]: programs,
  [invitations.href]: invitations,
  [weddingInvitations.href]: weddingInvitations,
  [rsvpCards.href]: rsvpCards,
  [enclosureCards.href]: enclosureCards,
  [envelopeLiners.href]: envelopeLiners,
  [bridalShowerInvitations.href]: bridalShowerInvitations,
  [rehearsalDinnerInvitations.href]: rehearsalDinnerInvitations,
  [changeTheDates.href]: changeTheDates,
  [changeTheDateCards.href]: changeTheDateCards,
  [changeTheDatePostCards.href]: changeTheDatePostCards,
  [saveTheDates.href]: saveTheDates,
  [saveTheDateCards.href]: saveTheDateCards,
  [saveTheDateCards.alternateHref as string]: saveTheDateCards,
  [saveTheDatePostCards.href]: saveTheDatePostCards,
  [saveTheDatePostCards.alternateHref as string]: saveTheDatePostCards,
  [saveTheDatePetiteCards.href]: saveTheDatePetiteCards,
  [savetheDateMagnets.href]: savetheDateMagnets,
  [savetheDateMagnets.alternateHref as string]: savetheDateMagnets,
  [holidayCards.href]: holidayCards,
  [envelopes.href]: envelopes,
  [responseCardEnvelopes.href]: responseCardEnvelopes,
  [weddingInvitationEnvelopes.href]: weddingInvitationEnvelopes,
  [saveTheDateCardEnvelopes.href]: saveTheDateCardEnvelopes,
  [bridalShowerInvitationEnvelopes.href]: bridalShowerInvitationEnvelopes,
  [rehearsalDinnerInvitationEnvelopes.href]: rehearsalDinnerInvitationEnvelopes,
  [thankYouCardEnvelopes.href]: thankYouCardEnvelopes,
  [changeTheDateCardEnvelopes.href]: changeTheDateCardEnvelopes,
}
