import { IncomingHttpHeaders } from 'http'
import { getClientHeaders } from '@/utils/helpers'
import { ApiError } from 'next/dist/server/api-utils'

const buildHeaders = <T>(headers: IncomingHttpHeaders = getClientHeaders()) => {
  const requestHeaders = new Headers()

  requestHeaders.set('Content-Type', 'application/json')
  requestHeaders.set('x-api-version', 'v2')
  requestHeaders.set('x-tenant', process.env.APP_BRAND || 'tk')

  if (headers) {
    if (headers.Cookie) requestHeaders.set('Cookie', headers.Cookie as string)
    if (headers.cookie) requestHeaders.set('cookie', headers.cookie)
    if (headers.token) requestHeaders.set('token', headers.token as string)
    if (headers['x-tenant']) requestHeaders.set('x-tenant', headers['x-tenant'] as string)
    if (headers['user-agent']) requestHeaders.set('user-agent', headers['user-agent'] as string)
    if (headers['User-Agent']) requestHeaders.set('User-Agent', headers['User-Agent'] as string)
  }

  return requestHeaders as T
}

export const storefrontAPIGraphqlRequest = <TQuery, TVar>(
  query: TQuery,
  variables: TVar,
  headers?: IncomingHttpHeaders
) => {
  const serverUrl = process.env.INTERNAL_STOREFRONT_API_URL ?? (process.env.APP_STOREFRONT_API_URL || '')

  return fetch(serverUrl, {
    method: 'POST',
    credentials: 'include',
    headers: buildHeaders(headers),
    body: JSON.stringify({ query, variables }),
  })
}

export async function storefrontAPIFetchIgnoreErrors<TQuery, TVar>(
  query: TQuery,
  variables: TVar,
  headers?: IncomingHttpHeaders
) {
  const response = await storefrontAPIGraphqlRequest(query, variables, headers)
  const responseBody = await response.json()

  if (responseBody.data) {
    return responseBody.data
  }
}

export async function storefrontAPIFetch<TQuery, TVar>(query: TQuery, variables: TVar, headers?: IncomingHttpHeaders) {
  const response = await storefrontAPIGraphqlRequest(query, variables, headers)
  const responseBody = await response.json()

  if (responseBody.errors) {
    const messages = responseBody.errors.map((error: any) => error.message).join('\n')
    const firstErrorStatusCode = responseBody?.errors?.[0]?.extensions?.status
    throw new ApiError(firstErrorStatusCode, messages)
  }

  return responseBody.data
}
