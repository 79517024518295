export const CartDraftsCounters = /* GraphQL */ `
  query getCartDraftsCounters($includeDrafts: Boolean!) {
    cart {
      itemCount
    }
    drafts @include(if: $includeDrafts) {
      findAllForUser {
        count
      }
    }
  }
`

export const CartCounter = /* GraphQL */ `
  query getCartCounter {
    cart {
      itemCount
    }
  }
`
