import HB from '@honeybadger-io/js'

const honeybadger = HB.configure({
  apiKey: process.env.APP_HONEYBADGER_API_KEY || '',
  environment: process.env.APP_HONEYBADGER_ENV || process.env.UNION_DEPLOYMENT_ENV,
  developmentEnvironments: ['dev', 'qa'],
  breadcrumbsEnabled: {
    dom: true,
    network: true,
    navigation: true,
    console: false,
  },
})

export default honeybadger
