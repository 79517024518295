import { useTracking } from '@/components/Tracking/useTracking/useTracking'
import { guestBooks } from '@/data/updatedCategoryHierarchyData'
import typewriter from '@/generated/analytics'
import * as Segment from '@/generated/analytics/segment'
import { CartDetailsItem } from '@/generated/graphql/graphql'
import { trackInteraction } from '@/modules/core/services/analytics'
import { Product, Variation } from '@/services/algolia'
import { PartialDraft } from '@/services/storefront/drafts/drafts.types'
import { buildImageURL } from '@/ui/Image/Image'
import { AnalyticsProductOld, AnalyticsUserData } from '@/utils/analytics/interfaces'
import { getCookieByName, getProductFormats, removeQueryParamsFromPathUrl } from '@/utils/helpers'
import { useCatalogAnalytics } from '.'

const PRODUCT_PATH = process.env.APP_PRODUCT_PATH || ''

const mergeSampleFields = (product: Product, variation: Variation) => ({
  ...product,
  variationId: variation.id,
  paperType: variation?.paperType,
  foilId: variation?.foil?.id,
})

const createAnalyticsUserData = (): AnalyticsUserData => {
  const userId = getCookieByName(window.document?.cookie, 'ajs_user_id') || 'unidentified'
  const anonymousId = getCookieByName(window.document?.cookie, 'ajs_anonymous_id') || 'unidentified'
  return {
    userId: userId.replace(/["]/g, '') ?? '',
    anonymousId: anonymousId.replace(/["]/g, '') ?? '',
  }
}

const createAnalyticsIntegrations = (): Segment.Options => {
  const gaCookie = getCookieByName(window.document?.cookie, '_ga').split('.').slice(-2).join('.')
  return {
    integrations: {
      ['Google Universal Analytics']: {
        ['clientId']: gaCookie,
      },
    },
  }
}

const createAnalyticsUrl = (slug: string) => {
  if (!slug) return ''
  return `${process.env.APP_PAPER_HOST}${process.env.APP_BASEPATH}${PRODUCT_PATH}/${removeQueryParamsFromPathUrl(slug)}`
}

const createAnalyticsDraft = (draft: PartialDraft, location: string, item: CartDetailsItem, position = 0) => {
  return {
    category: draft.portfolioItem.variation.product.type.name,
    image_url: draft.image,
    name: draft.portfolioItem.variation.product.name,
    price: item.price?.basePrice || 0,
    position,
    product_id: draft.portfolioItem.variation.catalogProductId,
    quantity: draft.quantity,
    sample: draft.portfolioItem.variation.isSample,
    sku: draft.portfolioItem.variation.id,
    location: location,
    url: createAnalyticsUrl(item?.slug || ''),
    variant: {
      color: item.variation?.colorThemeName,
      material: item.variation?.paperTypeName,
      printing_options: item.variation?.envelopePrintingOptionName,
      foil_color: item.variation?.foilName,
      flap_type: item.variation?.flapTypeCode,
      format: item?.variation?.product.productFormat.name,
      paper_color: item.variation?.paperColorCode,
    },
    hasDesign: !!draft.portfolioItem.notes,
  }
}

const createAnalyticsProductOld = (
  memberId: string | null,
  product: Product,
  location: string,
  variantOverride?: string,
  position = 0
) => {
  const defaultVariant = product.variations && product.variations[0]
  const paperType = defaultVariant?.paperType?.name || 'No Paper Type'
  const foilId = defaultVariant?.foil?.id ? ` Foil` : 'No Foil'
  const material: AnalyticsProductOld['material'] = [paperType, foilId]
  const format = getProductFormats(product)

  return {
    memberId,
    position,
    price: NaN,
    //!Confirm that this change is ok
    affiliation: process.env.APP_BRAND || undefined,
    sku: undefined,
    category: product.categoryName || '',
    location: location,
    name: product.name,
    material,
    format,
    product_id: defaultVariant?.id ?? '',
    url: createAnalyticsUrl(product.slug),
    variant: (variantOverride || defaultVariant?.colorway?.name || '').toLowerCase(),
  }
}

type ProductData = {
  product: Product
  selectedVariant: Variation
  imageUri: string
  isSample: boolean
  position?: number
}

const createAnalyticsProduct = ({ position = 0, ...productData }: ProductData) => {
  const { product, selectedVariant, imageUri, isSample } = productData
  const format = getProductFormats(product)
  const category = Array.isArray(product.categoryName)
    ? product.categoryName[product.categoryName.length - 1]
    : product.categoryName
  const quantity = isSample || category === guestBooks.name ? 1 : 100
  return {
    position,
    price: product.unitPriceAt100 || NaN,
    product_id: product.id, // catalogProductId
    sku: selectedVariant.id,
    category,
    name: product.name,
    quantity,
    image_url: imageUri,
    sample: isSample,
    variant: {
      color: selectedVariant.colorway?.name,
      material: selectedVariant.paperType?.name,
      format,
      foil_color: selectedVariant.foil?.name,
      paper_color: selectedVariant.paperColor?.name,
      flap_type: selectedVariant.flapType?.name,
    },
    url: createAnalyticsUrl(product.slug),
  }
}
const analyticsProductsHelper = (products: Product[]) =>
  products.map((product, i) =>
    createAnalyticsProduct({
      product,
      isSample: false,
      selectedVariant: product.variations[0],
      imageUri: buildImageURL(product.variations[0].heroImagePath?.slice(1) || ''),
      position: i,
    })
  )

const useTrackProductsViewed = () => {
  const { location, category } = useCatalogAnalytics()
  const trackingData = useTracking()

  return (products: Product[], listId: string) => {
    const analyticsProductsList = analyticsProductsHelper(products)

    const options = {
      category,
      location,
      products: analyticsProductsList,
      list_id: listId,
      ...trackingData,
    }
    trackInteraction('Product List Viewed', options)
  }
}

export {
  analyticsProductsHelper,
  createAnalyticsDraft,
  createAnalyticsIntegrations,
  createAnalyticsProduct,
  createAnalyticsProductOld,
  createAnalyticsUrl,
  createAnalyticsUserData,
  mergeSampleFields,
  typewriter,
  useTrackProductsViewed,
}
