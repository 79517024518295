import { ComponentType, createContext, PropsWithChildren } from 'react'

export interface UserProfile {
  id: string
  avatar: UserAvatar
  email: string
  unreadMessagesCount: string
  username: string
  firstName?: string
  lastName?: string
}

export interface UserAvatar {
  url: string | null
  color: string
}

type UserProviderProps = {
  user?: UserProfile | null
  isLoggedIn: boolean
}

export const UserContext = createContext<UserProviderProps | null>(null)

export const UserProvider: ComponentType<PropsWithChildren<Pick<UserProviderProps, 'user'>>> = ({ user, children }) => {
  return (
    <UserContext.Provider
      value={{
        user,
        isLoggedIn: !!user,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
