import { Features } from '@/services/unleash.service'
import { createContext, ComponentType, PropsWithChildren } from 'react'

export type FeatureToggleTypes = keyof typeof FEATURES | Features

export type FeaturesType = Partial<Record<FeatureToggleTypes, boolean>>

export type FeatureToggleProviderProps = {
  features?: FeaturesType
}

export const cast = (env: string | undefined): boolean => (env && !!+env) || false

export const FEATURES = {
  productDesigner: cast(process.env.FT_PRODUCT_DESIGNER),
  collectionsSubnav: cast(process.env.FT_COLLECTIONS_SUBNAV),
  seasonalCollectionSubnavLink: cast(process.env.FT_SEASONAL_COLLECTION_SUBNAV),
  myDrafts: cast(process.env.FT_DRAFTS),
  nativeLinks: false,
  cmp: cast(process.env.FT_CMP),
}

export const FeatureToggleContext = createContext<FeaturesType>(FEATURES)

export const FeatureToggleProvider: ComponentType<PropsWithChildren<FeatureToggleProviderProps>> = ({
  children,
  features = {},
}) => {
  return (
    <FeatureToggleContext.Provider
      value={{
        ...FEATURES,
        ...features,
      }}
    >
      {children}
    </FeatureToggleContext.Provider>
  )
}
