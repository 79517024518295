import camelCase from 'lodash.camelcase'

const API_KEY = String(process.env.EXPERIMENTATION_API_KEY)

const EXPERIMENT_API_URL = String(process.env.APP_EXPERIMENT_API_URL)

const isJson = (contentType: string | null) => contentType?.includes('application/json')

export async function getFromAPI<T>(route: string): Promise<T | null> {
  const res = await fetch(`${EXPERIMENT_API_URL}/${route}`, {
    headers: {
      'x-api-key': API_KEY,
      'Accept-Version': 'v1',
    },
  })

  if (!res.ok) {
    throw new Error(`Error occurred while fetching Flipper data: ${res.statusText}`)
  }

  if (!isJson(res.headers.get('content-type'))) {
    return null
  }

  const body = await res.json()

  return Object.entries(body).reduce((acc, [k, v]) => {
    return {
      ...acc,
      [camelCase(k)]: v,
    }
  }, {}) as T
}
