import { TrackingProps } from '@/components/Tracking/TrackingProvider/TrackingProvider'
import { createAnalyticsIntegrations } from '@/modules/core/contexts/CatalogAnalytics/hooks'
import { initializeTracking } from './tracker'

export const pageView = (props: TrackingProps) => {
  if (typeof window === 'undefined') return

  initializeTracking()
  const { page } = window.analytics

  page && page(props)
}

function mergeBaseOptions(props: Record<string, any>) {
  return { ...props, section: 'paper' }
}

export const trackInteraction = (event: string, properties: Record<string, any>) => {
  initializeTracking()

  const { track } = window.analytics
  const integrations = createAnalyticsIntegrations()
  track && track(event, mergeBaseOptions(properties), integrations)
}

export const trackInteractionPromise = (event: string, properties: Record<string, any>): Promise<string> => {
  initializeTracking()

  const { track } = window.analytics
  const integrations = createAnalyticsIntegrations()
  const trackCallPromise: Promise<string> = new Promise((resolve) => {
    track &&
      track(event, mergeBaseOptions(properties), integrations, () => {
        resolve(`${event} tracked`)
      })
  })
  const timeout: Promise<string> = new Promise((resolve) => {
    const id = setTimeout(() => {
      clearTimeout(id)
      resolve('Timeout of 3s')
    }, 3000)
  })

  return Promise.race([trackCallPromise, timeout])
}
