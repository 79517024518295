const TENANTS = ['tk', 'ww'] as const

export type Tenant = (typeof TENANTS)[number]

export const CONFIG = {
  tenant: {
    shortName: process.env.APP_BRAND as Tenant,
  },
} as const

export function isTenant(tenant: Tenant) {
  return tenant === (process.env.APP_BRAND as Tenant)
}
